export const formatDate = (inputString) => {
    const date = new Date(inputString);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    return date.toLocaleString('ru-RU', options).replace(',', '');
};

export const formatDateToLongString = (dateStr) => {
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('ru-RU', options).format(date);
};
