import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';
import { promocodeInitialState } from './initialStates/promocodeInitialState';

export const getPromocode = createAsyncThunk('promocode/getPromocode', async ({ code }, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getPromocode(code);
    dispatch(toggleIsFetching(false));
    if (response.id && response.statusCode !== 404) {
        dispatch(setPromocode(response));
        return response;
    }

    return response.message;
});

export const getPromocodes = createAsyncThunk('promocode/getPromocodes', async (_, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getAllPromocodes();
    dispatch(toggleIsFetching(false));
    if (Array.isArray(response)) {
        dispatch(setPromocodes(response));
        return response;
    }

    return response.message;
});

export const setUserPromocode = createAsyncThunk('promocode/setUserPromocode', async ({ sale }, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.setPromocode({ sale: Number(sale) });
    dispatch(toggleIsFetching(false));
    if (response.id && response.statusCode !== 400) {
        dispatch(setPromocode(response));
        dispatch(getPromocodes());
        return response;
    }

    return response.message;
});

export const updatePromocode = createAsyncThunk('promocode/updatePromocode', async ({ code, status }, { dispatch }) => {
    dispatch(toggleIsFetching(true));    
    const response = await api.updatePromocode({ code, status });
    dispatch(toggleIsFetching(false));
    if (response.id && response.statusCode !== 400) {
        dispatch(setPromocode(response));
        dispatch(getPromocodes());
        return response;
    }

    return response.message;
});

const promocodeSlice = createSlice({
    name: 'promocode',
    initialState: promocodeInitialState,
    reducers: {
        setPromocode: (state, action) => {
            state.promocode = action.payload;
        },
        setPromocodes: (state, action) => {
            state.promocodes = action.payload;
        },
        toggleIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        applyPromocode: (state, action) => {
            state.apply = action.payload;
        }
    },
});

export const { toggleIsFetching, setPromocode, setPromocodes, applyPromocode } = promocodeSlice.actions;

export default promocodeSlice.reducer;
