import { createSlice } from '@reduxjs/toolkit';
import { sortingInitialState } from './initialStates/sortingInitialState';

const sortingSlice = createSlice({
    name: 'sorting',
    initialState: sortingInitialState,
    reducers: {
        setSortValue: (state, action) => {
            state.sortValue = action.payload;
        },
    },
});

export const { setSortValue } = sortingSlice.actions;

export default sortingSlice.reducer;
