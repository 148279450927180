export const createQueryParams = ({ sex, types, sortValue = '', brands = [], colors = [], prices = {} }) => {
    let path = `/products${sex ? '/' + sex : ''}`;
    let params = [];

    if (types && types.length > 0) {
        params.push(...types.map((type) => `type=${encodeURIComponent(type)}`));
    }

    if (sortValue) {
        params.push(`sortValue=${encodeURIComponent(sortValue)}`);
    }

    if (brands && brands.length > 0) {
        params.push(...brands.map((brand) => `brand=${encodeURIComponent(brand)}`));
    }

    if (colors && colors.length > 0) {
        params.push(...colors.map((color) => `color=${encodeURIComponent(color)}`));
    }

    if (prices.minPrice || prices.maxPrice) {
        if (prices.minPrice) {
            params.push(`minPrice=${encodeURIComponent(prices.minPrice)}`);
        }
        if (prices.maxPrice) {
            params.push(`maxPrice=${encodeURIComponent(prices.maxPrice)}`);
        }
    }

    if (params.length > 0) {
        path += `?${params.join('&')}`;
    }

    return path;
};
