export const BRANDS = {
    adidas: 'Adidas',
    reebok: 'Reebok',
    nike: 'Nike',
    puma: 'Puma',
    underArmour: 'Under Armour',
    tnf: 'The North Face',
    anta: 'Anta',
    newBalance: 'New Balance',
    file: 'Fila',
    vans: 'Vans',
    timberland: 'Timberland',
    asics: 'Asics',
    drMartens: 'Dr Martens',
    stoneIsland: 'Stone Island',
    levis: 'Levis',
    nativeFitzsimmons: 'Native Fitzsimmons',
    converse: 'Converse',
    saucony: 'Saucony',
    jordan: 'Jordan',
    carharttWIP: 'Carhartt WIP',
    dickies: 'Dickies',
    burberry: 'Burberry',
    tommyHilfiger: 'Tommy Hilfiger',
    armaniExchange: 'Armani Exchange',
    poloRalphLauren: 'Polo Ralph Lauren',
    lacoste: 'Lacoste',
    liNing: 'LiNing',
    kappa: 'Kappa',
    cpCompany: 'C.P. Company',
};

export const BRANDS_BY_CATEGORY = {
    popular: {
        title: 'Популярное',
        brands: {
            adidas: BRANDS.adidas,
            reebok: BRANDS.reebok,
            nike: BRANDS.nike,
            puma: BRANDS.puma,
            underArmour: BRANDS.underArmour,
            newBalance: BRANDS.newBalance,
            asics: BRANDS.asics,
            saucony: BRANDS.saucony,
            jordan: BRANDS.jordan,
            liNing: BRANDS.liNing,
            anta: BRANDS.anta,
        },
    },
    premium: {
        title: 'Премиум',
        brands: {
            burberry: BRANDS.burberry,
            tommyHilfiger: BRANDS.tommyHilfiger,
            armaniExchange: BRANDS.armaniExchange,
            poloRalphLauren: BRANDS.poloRalphLauren,
            lacoste: BRANDS.lacoste,
            stoneIsland: BRANDS.stoneIsland,
            cpCompany: BRANDS.cpCompany,
            carharttWIP: BRANDS.carharttWIP,
            dickies: BRANDS.dickies,
        },
    },
    street: {
        title: 'Уличное',
        brands: {
            vans: BRANDS.vans,
            tnf: BRANDS.tnf,
            file: BRANDS.file,
            timberland: BRANDS.timberland,
            drMartens: BRANDS.drMartens,
            converse: BRANDS.converse,
            nativeFitzsimmons: BRANDS.nativeFitzsimmons,
            kappa: BRANDS.kappa,
        },
    },
};
