import { useMobileMenu } from '../../hooks';

export const HeaderBurger = () => {
    const { isMobileMenuOpen, openMobileMenu, closeMobileMenu } = useMobileMenu();

    return (
        <div className='header__right-burger'>
            <input type='checkbox' id='check' checked={isMobileMenuOpen} onChange={isMobileMenuOpen ? closeMobileMenu : openMobileMenu} />
            <label htmlFor='check'>
                <span></span>
                <span></span>
                <span></span>
            </label>
        </div>
    );
};
