import { EMOJI } from '../emoji';

export const DELIVERY = {
    title: `Доставка ${EMOJI.cars[0]}`,
    items: [
        {
            title: 'Для доставки по России:',
            subtitle: 'Дорогие пользователи и гости нашего интернет-магазина! Мы отправляем товары только логистической компанией СДЭК.',
            items: [
                'Отправка осуществляется по полной предоплате (Товар + Доставка).',
                'От вас нужны будут данные: ФИО, номер телефона, город и адрес пункта СДЭК в вашем городе, где будет удобно забрать заказ. Либо можете заказать доставку на дом (стоит дороже).',
                'Если вы успеете оплатить до 18:00 (МСК) - товар отправляется в день оплаты. Если позже - на следующий день. (Очень редко случаются трудности и товар может быть отправлен чуть позже).',
                'Сроки доставки и цену вы можете узнать напрямую у нас @sportnn21 (Telegram). Либо напишите нам в Instagram Direct или в сообщения авито. Ссылки в блоке "контакты".',
            ],
        },
        {
            title: 'Для доставки по области и городу Нижний Новгород:',
            subtitle:
                'Доступна также доставка Яндекс курьером или Яндекс такси. Доставка осуществляется по полной предоплате за товар и доставку, либо услуги перевозки вы можете оплатить сами наличными или картой по приезду курьера ему лично.',
            items: [
                'Стоимость доставки ориентировочно 300 рублей, но может меняться из-за расстояния от нашего магазина до вашего дома, времени суток и погодных условий.',
            ],
        },
    ],
    lastText: 'Удачных покупок!',
};
