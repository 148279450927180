import cn from 'classnames';
import { getButtonStyle } from '../../../utils';
import { NavLink } from 'react-router-dom';

export const Button = ({ className, children, disabled = false, onClick, variant = 'white', type = 'button', isLink = false, href, isLittle }) => {
    const style = getButtonStyle(disabled ? 'disabled' : variant);
    return (
        <>
            {isLink ? (
                <NavLink onClick={onClick} className={cn('button', isLittle && 'button-little', className)} style={style} to={href}>
                    {children}
                </NavLink>
            ) : (
                <button
                    onClick={onClick}
                    disabled={disabled}
                    className={cn('button', isLittle && 'button-little', className)}
                    type={type}
                    style={style}
                >
                    {children}
                </button>
            )}
        </>
    );
};
