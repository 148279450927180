export const SIZES = {
    shoes: {
        33: '33',
        33.5: '33.5',
        34: '34',
        34.5: '34.5',
        35: '35',
        35.5: '35.5',
        36: '36',
        36.5: '36.5',
        37: '37',
        37.5: '37.5',
        38: '38',
        38.5: '38.5',
        39: '39',
        39.5: '39.5',
        40: '40',
        40.5: '40.5',
        41: '41',
        41.5: '41.5',
        42: '42',
        42.5: '42.5',
        43: '43',
        43.5: '43.5',
        44: '44',
        44.5: '44.5',
        45: '45',
        45.5: '45.5',
        46: '46',
        46.5: '46.5',
        47: '47',
        47.5: '47.5',
        48: '48',
        48.5: '48.5',
        49: '49',
        49.5: '49.5',
        50: '50',
        50.5: '50.5',
    },
    clothes: {
        XXS: 'XXS',
        XS: 'XS',
        S: 'S',
        M: 'M',
        L: 'L',
        XL: 'XL',
        XXL: 'XXL',
        XXXL: 'XXXL',
    },
    accessories: {
        NS: 'NS',
        OS: 'OS',
    },
};