export const promocodeInitialState = {
    isFetching: false,
    promocode: {
        id: null,
        code: null,
        status: false,
        sale: null
    },
    apply: null,
    promocodes: []
};

