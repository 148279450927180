import { EMOJI } from '../emoji';

export const GUARANTEE = {
    title: `Гарантии ${EMOJI.face.happy[0]}`,
    items: [
        {
            title: 'Гарантия на покупку',
            subtitle:
                'Мы даем гарантию на покупку в нашем магазине 14 календарных дней. В течение этого времени вы можете вернуть деньги за товар обратно или обменять товар на другой (в том числе с доплатой).',
        },
        {
            subtitle:
                'Возврат предусмотрен, когда товар имеет брак, либо не подошел Вам по размеру. Возврат товара предусмотрен так же и при покупке через интернет. В таком случае, товар считается купленным, когда покупатель забирает его из службы доставки. С этого времени начинается гарантийный период 14 дней.',
        },
        {
            title: 'Гарантия на оригинальность',
            subtitle:
                'Мы продаем только оригинальную продукцию и готовы к любым проверкам со стороны покупателя. Вы можете проверить вещь лично, либо отправить на экспертизу. Можете пройти Legit Check у независимой компании или эксперта. В случае, если Вам скажут, что товар является поддельным, мы вернем Вам деньги и подарим приобретаемый товар.',
        },
    ],
};
