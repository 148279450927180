import { COMPANY_INFO } from '../_company';
import { EMOJI } from '../emoji';

export const COOKIE = {
    title: `Как и зачем магазин ${COMPANY_INFO.nameEng} использует файлы cookie? ${EMOJI.face.thinking[0]}`,
    subtitle:
        'На нашем сайте мы используем cookie-файлы, чтобы совершенствовать сервис для вас. Для обеспечения корректного взаимодействия на сайте пользователь признает, что согласен на использование файлов cookie и ознакомлен с политикой в отношении них.',
    items: [
        {
            title: 'Что такое cookie?',
            subtitle: [
                'Cookie – это небольшие файлы с данными, которые сохраняются на ваш компьютер, мобильное устройство веб-сервисом/браузером. Использование файлов cookie необходимо почти для каждого сайта, без них невозможно нормальное функционирование интернет-ресурсов. Все Персональные данные, которые могут быть получены в связи с использованием cookie обрабатываются в соответствии с ',
                { text: 'нашей политикой обработки персональных данных.', link: '/policy', internal: true },
            ],
        },
        {
            title: `Какие виды cookie использует магазин ${COMPANY_INFO.nameEng}?`,
            subtitle: `${COMPANY_INFO.nameEng} использует несколько видов cookie:`,
            items: [
                'Постоянные. Такие файлы хранятся до окончания их срока действия или пока Вы их не удалите.',
                'Сессионные. Такой вид файлов хранится только на протяжении вашего сеанса в браузере и при закрытии удаляются.',
            ],
        },
        {
            subtitle: 'Также принято выделять файлы cookie по их целевому назначению:',
            items: [
                'Обязательные. Без таких cookie невозможна корректная работа сайта, они обязательны для использования всеми пользователями. Вы можете отключить их при помощи настроек своего браузера, при этом сайт или его компоненты будут работать некорректно.',
                'Функциональные. Такой вид cookie помогает в осуществлении навигации по сайту, а также позволяет использовать функционал веб-страницы.',
                'Рекламные. Данный вид cookie используется в первую очередь в маркетинговых целях, помогая нам предложить Вам лучшие персонализированные предложения.',
                'Аналитические. Такие cookie файлы используются для сбора статистических сведений о работе с сайтом. Например, анализируют количество пользователей.',
            ],
        },
        {
            title: `Зачем ${COMPANY_INFO.nameEng} использует файлы cookie`,
            subtitle: `${COMPANY_INFO.nameEng} использует файлы cookie для оптимизации вашей работы с сайтом. Они позволяют запомнить сделанный вами выбор (например, выбор города, в котором вы находитесь).`,
        },
        { subtitle: 'Обращаем ваше внимание на то, что файлы cookie не несут угрозы безопасности вашим данным.' },
        {
            title: 'Можете ли вы управлять cookie и удалять их?',
            subtitle:
                'Да, вы можете управлять и контролировать ваши файлы cookie. При этом обращаем ваше внимание на то, что в случае отказа от cookie все персонализированные настройки будут сброшены, а также отключив их, вы можете потерять доступ к некоторым разделам сайта.',
        },
        { subtitle: 'Наиболее простой и оптимальный способ – использования настроек браузера.' },
        {
            title: 'Сроки хранения файлов cookie',
            subtitle:
                'Файлы cookie будут храниться на вашем устройстве в течение периода, необходимого для достижения их цели, после чего они будут автоматически удалены с вашего устройства. Для различных видов файлов cookie срок хранения на вашем устройстве может отличаться.',
        },
        {
            subtitle: 'Инструкции по настройке cookie есть для каждого браузера, например:',
            items: [
                { text: 'Google Chrome', link: 'https://support.google.com/chrome/answer/95647', internal: false },
                { text: 'Safari', link: 'https://support.apple.com/ru-ru/guide/safari/sfri11471/mac', internal: false },
                {
                    text: 'Mozilla Firefox',
                    link: 'https://support.mozilla.org/ru/kb/cookies-information-websites-store-on-your-computer',
                    internal: false,
                },
            ],
        },
    ],
    lastText: 'Условия настоящей политики могут меняться, мы рекомендуем регулярно следить за обновлением данного документа.',
};
