import { useEffect, useState } from 'react';
import { shouldShowNewYearDecor } from '../utils';

export const useShowNewYearDecor = () => {
    const [isShowNewYearDecor, setIsShowNewYearDecor] = useState(false);

    useEffect(() => {
        setIsShowNewYearDecor(shouldShowNewYearDecor());
    }, []);

    return isShowNewYearDecor;
};
