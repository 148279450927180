import { COLORS } from '../constants';

export const HeaderLogoSVG = ({ size = 100, color = COLORS.black.value, className = '' }) => {
    return (
        <svg
            version='1.0'
            xmlns='http://www.w3.org/2000/svg'
            width={size}
            height={size}
            viewBox='0 0 285.000000 289.000000'
            preserveAspectRatio='xMidYMid meet'
            className={className}
        >
            <g transform='translate(0.000000,289.000000) scale(0.100000,-0.100000)' fill={color ?? 'currentColor'} stroke='none'>
                <path
                    d='M2400 2828 c-184 -116 -269 -148 -458 -173 -64 -9 -120 -19 -125 -24
                        -20 -20 36 -32 144 -29 100 3 112 1 125 -16 12 -17 14 -133 14 -738 l0 -718
                        -26 -17 c-19 -12 -55 -18 -135 -21 -108 -5 -133 -12 -110 -35 14 -14 940 -11
                        954 3 17 16 -19 25 -116 30 -89 5 -99 8 -125 33 l-27 27 -5 864 c-3 476 -7
                        867 -10 869 -3 3 -48 -22 -100 -55z'
                />
                <path
                    d='M545 2863 c-183 -26 -333 -123 -396 -257 -62 -133 -60 -307 4 -404
                        89 -132 278 -131 338 2 38 85 14 199 -52 248 -44 32 -59 37 -143 48 -38 5 -73
                        14 -77 22 -17 27 -9 85 17 123 57 85 139 126 248 127 147 0 241 -59 304 -193
                        43 -92 57 -166 55 -309 -1 -204 -47 -366 -145 -517 -62 -97 -355 -390 -545
                        -547 -134 -110 -152 -131 -133 -150 8 -8 211 -11 670 -11 597 0 660 2 660 16
                        0 46 33 351 50 463 20 132 19 166 -5 166 -20 0 -25 -12 -50 -112 -13 -48 -30
                        -100 -39 -113 -41 -63 -116 -74 -541 -81 l-340 -6 133 81 c402 243 638 485
                        708 726 21 72 23 198 4 275 -46 195 -224 354 -443 395 -73 14 -211 18 -282 8z'
                />
                <path
                    d='M696 755 c-51 -59 -104 -92 -167 -103 -53 -9 -54 -26 -3 -30 l41 -3
                        5 -267 c4 -228 7 -272 22 -293 50 -78 242 -76 331 2 49 43 47 70 -2 30 -62
                        -51 -125 -39 -142 27 -5 20 -12 141 -16 269 l-7 232 83 3 c53 2 84 7 86 15 3
                        9 -19 13 -79 15 l-83 3 -3 78 c-2 42 -6 77 -11 77 -4 0 -29 -25 -55 -55z'
                />
                <path
                    d='M359 658 c-15 -21 -19 -21 -68 -10 -76 17 -136 15 -181 -8 -73 -37
                        -119 -128 -106 -212 14 -91 63 -135 209 -190 92 -34 127 -64 127 -109 0 -118
                        -139 -145 -245 -47 -30 26 -49 56 -60 89 -10 28 -18 49 -20 47 -1 -2 -5 -52
                        -8 -113 -6 -102 -5 -108 10 -88 19 28 34 28 125 3 40 -11 84 -20 98 -20 53 0
                        106 23 146 64 76 75 83 208 16 279 -31 33 -70 55 -179 98 -98 38 -123 61 -123
                        109 0 91 132 121 215 49 18 -16 41 -50 51 -75 9 -25 20 -41 24 -37 8 10 0 193
                        -8 193 -4 -1 -14 -11 -23 -22z'
                />
                <path
                    d='M1173 639 c-67 -26 -147 -98 -181 -163 -24 -45 -27 -62 -27 -146 0
                        -80 4 -102 23 -138 36 -67 98 -129 161 -159 48 -24 68 -28 141 -28 73 0 93 4
                        142 28 71 35 150 119 172 183 23 67 21 161 -6 231 -28 77 -122 172 -192 196
                        -67 23 -168 21 -233 -4z m180 -29 c36 -42 47 -109 47 -280 0 -178 -12 -243
                        -50 -282 -38 -38 -74 -36 -115 5 -44 44 -59 117 -59 287 -1 206 36 300 118
                        300 26 0 40 -7 59 -30z'
                />
                <path
                    d='M1880 639 c-36 -10 -100 -20 -142 -21 -85 -3 -102 -14 -36 -24 78
                        -13 78 -12 78 -283 0 -132 -3 -246 -6 -255 -4 -10 -18 -16 -38 -16 -32 0 -65
                        -14 -50 -21 20 -9 394 2 394 11 0 6 -17 10 -37 10 -67 0 -68 3 -71 167 -4 192
                        9 326 35 362 24 32 81 44 68 14 -31 -70 -5 -123 60 -123 53 0 85 36 85 95 0
                        38 -5 52 -30 77 -28 28 -35 30 -85 26 -61 -5 -86 -22 -116 -78 l-18 -35 -1 42
                        c0 44 -8 74 -19 72 -3 -1 -35 -10 -71 -20z'
                />
                <path
                    d='M2500 651 c-90 -29 -165 -96 -204 -180 -30 -66 -30 -216 0 -282 31
                        -67 87 -124 152 -156 49 -24 69 -28 142 -28 94 0 141 18 191 72 32 34 69 93
                        69 111 0 23 -17 12 -30 -18 -19 -46 -94 -107 -141 -115 -61 -10 -127 26 -157
                        87 -28 54 -48 186 -38 240 6 35 7 35 68 42 33 3 114 6 178 6 l117 0 -8 48
                        c-21 109 -105 173 -237 178 -42 2 -88 0 -102 -5z m145 -41 c9 -17 19 -60 22
                        -95 l6 -65 -93 0 -92 0 7 48 c9 59 31 113 52 130 9 6 32 12 50 12 26 0 36 -6
                        48 -30z'
                />
            </g>
        </svg>
    );
};
