import { COMPANY_INFO } from '../_company';
import { EMOJI } from '../emoji';

export const POLICY = {
    title: `Политика конфиденциальности ${EMOJI.docs[0]}`,
    date: '1 августа 2023 года (01.08.2023 г.)',
    subtitle: `Спасибо, что посетили ${COMPANY_INFO.nameEng} ("Компания", "Мы", "Наш" или "Нас"). В настоящей Политике конфиденциальности описывается, как мы собираем, используем и защищаем личную информацию, полученную от пользователей нашего веб-сайта спортивной одежды. Мы стремимся защищать вашу конфиденциальность и поддерживать конфиденциальность вашей информации.`,
    items: [
        {
            title: 'Информация, которую мы собираем:',
            subtitle:
                'Личная информация. Когда вы просматриваете наш веб-сайт или совершаете покупку на нем, мы можем собирать личную информацию, такую как ваше полное имя, адрес электронной почты, номер телефона и город с пунктом выдачи.',
        },
        {
            title: 'Использование информации:',
            subtitle:
                'Персонализация и поддержка клиентов. Мы можем использовать вашу личную информацию, чтобы предоставлять персонализированный опыт, отвечать на ваши запросы и предлагать поддержку клиентов.',
        },
        {
            subtitle:
                'Обработка заказа и отправка: мы можем использовать вашу личную информацию для обработки и выполнения ваших заказов, включая проверку платежных реквизитов, обновление статуса доставки и управление возвратами или обменами.',
        },
        {
            subtitle:
                'Маркетинговые коммуникации. С вашего согласия мы можем отправлять вам рекламные электронные письма, чтобы информировать вас о новых продуктах, специальных предложениях или предстоящих мероприятиях. Вы можете отказаться от этих сообщений в любое время, следуя инструкциям по отказу от подписки, приведенным в электронном письме.',
        },
        {
            subtitle:
                'Соблюдение законодательства. Мы можем обрабатывать вашу личную информацию в соответствии с применимыми законами, правилами или юридическими обязательствами.',
        },
        {
            title: 'Обмен информацией:',
            subtitle:
                'Сторонние поставщики услуг: мы можем передавать вашу личную информацию доверенным сторонним поставщикам услуг, работающим от нашего имени, для облегчения обработки заказов, обработки платежей, аналитики веб-сайта и других бизнес-операций. Эти поставщики услуг по контракту обязаны поддерживать конфиденциальность и безопасность вашей информации.',
        },
        {
            subtitle:
                'Юридические требования. Мы можем раскрывать вашу личную информацию, если это требуется по закону или если мы считаем, что такие действия необходимы для соблюдения юридических обязательств, защиты наших прав или прав других лиц или расследования мошенничества или проблем с безопасностью.',
        },
        {
            title: 'Безопасность данных:',
            subtitle:
                'Мы применяем разумные меры безопасности для защиты вашей личной информации от несанкционированного доступа, раскрытия, изменения или уничтожения. Тем не менее, пожалуйста, поймите, что ни один метод передачи данных через Интернет или электронное хранилище не является безопасным на 100%, и мы не можем гарантировать абсолютную безопасность.',
        },
        {
            title: 'Хранение данных:',
            subtitle:
                'Мы будем хранить вашу личную информацию до тех пор, пока это необходимо для достижения целей, изложенных в настоящей Политике конфиденциальности, за исключением случаев, когда более длительный срок хранения требуется или разрешен законом.',
        },
        {
            title: 'Ваши права:',
            subtitle:
                'У вас есть право доступа, обновления или удаления вашей личной информации в наших записях. Если вы хотите воспользоваться любым из этих прав или у вас есть какие-либо вопросы, связанные с конфиденциальностью, свяжитесь с нами, используя информацию, указанную ниже.',
        },
        {
            title: 'Конфиденциальность детей:',
            subtitle:
                'Наш веб-сайт не предназначен для лиц моложе 14 лет. Мы сознательно не собираем личную информацию от детей. Если вы считаете, что мы непреднамеренно получили личную информацию от ребенка, немедленно свяжитесь с нами.',
        },
        {
            title: 'Изменения в настоящей Политике конфиденциальности:',
            subtitle:
                'Мы можем время от времени обновлять настоящую Политику конфиденциальности без предварительного уведомления. Пересмотренная политика будет размещена на этой странице, а дата вступления в силу будет указана вверху.',
        },
        {
            title: 'Свяжитесь с нами:',
            subtitle:
                'Если у вас есть какие-либо вопросы или опасения относительно настоящей Политики конфиденциальности или наших методов обеспечения конфиденциальности, свяжитесь с нами по адресу:',
        },
        {
            title: COMPANY_INFO.nameRus,
            subtitle: `${COMPANY_INFO.address} ${COMPANY_INFO.city}, ${COMPANY_INFO.postCode} ${COMPANY_INFO.country}`,
        },
        {
            subtitle: [
                {
                    text: COMPANY_INFO.emails.main,
                    link: `mailto:${COMPANY_INFO.emails.main}`,
                    internal: false,
                },
            ],
        },
        {
            subtitle: [
                {
                    text: COMPANY_INFO.phones.main,
                    link: `tel:${COMPANY_INFO.phones.main}`,
                    internal: false,
                },
            ],
        },
    ],
};
