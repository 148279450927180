import { createQueryParams } from '../utils/createQueryParams';
import { BRANDS, BRANDS_BY_CATEGORY, SEX, TYPE } from './filters';
import { SORT_VALUES } from './sorting';

export const CATEGORIES_NAMES = {
    new: 'Новинки',
    men: SEX.men.cases.plural_dative,
    women: SEX.women.cases.plural_dative,
    unisex: SEX.unisex.value,
    brands: 'Бренды',
    sale: 'Скидки',
    popular: 'Популярное',
    assortment: 'Ассортимент',
    all: ['Вся', 'Все'],
};

export const CATEGORIES = [
    {
        title: CATEGORIES_NAMES.new,
        to: createQueryParams({ sortValue: SORT_VALUES.new.name }),
        links: [
            {
                title: SEX.men.cases.singular,
                to: createQueryParams({ sex: SEX.men.name, sortValue: SORT_VALUES.new.name }),
                isHeader: true,
                links: [
                    {
                        title: TYPE.shoes,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: TYPE.clothes,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: TYPE.accessories,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
                    },
                ],
            },
            {
                title: SEX.women.cases.singular,
                to: createQueryParams({ sex: SEX.women.name, sortValue: SORT_VALUES.new.name }),
                isHeader: true,
                links: [
                    {
                        title: TYPE.shoes,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: TYPE.clothes,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: TYPE.accessories,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
                    },
                ],
            },
            {
                title: SEX.unisex.cases.singular,
                to: createQueryParams({ sex: SEX.unisex.name, sortValue: SORT_VALUES.new.name }),
                isHeader: true,
                links: [
                    {
                        title: TYPE.shoes,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: TYPE.clothes,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: TYPE.accessories,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
                    },
                ],
            },
        ],
    },
    {
        title: CATEGORIES_NAMES.men,
        to: createQueryParams({ sex: SEX.men.name }),
        links: [
            {
                title: TYPE.clothes,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], brands: [BRANDS.nike, BRANDS.adidas] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], brands: [BRANDS.adidas] }),
                    },
                ],
            },
            {
                title: TYPE.shoes,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], brands: [BRANDS.nike, BRANDS.adidas, BRANDS.reebok] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.reebok,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], brands: [BRANDS.reebok] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], brands: [BRANDS.adidas] }),
                    },
                ],
            },
            {
                title: TYPE.accessories,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], brands: [BRANDS.nike, BRANDS.adidas, BRANDS.reebok] }),
                    },
                    {
                        title: BRANDS.dickies,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], brands: [BRANDS.dickies] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.reebok,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], brands: [BRANDS.reebok] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], brands: [BRANDS.adidas] }),
                    },
                ],
            },
        ],
    },
    {
        title: CATEGORIES_NAMES.women,
        to: createQueryParams({ sex: SEX.women.name }),
        links: [
            {
                title: TYPE.clothes,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], brands: [BRANDS.nike, BRANDS.adidas] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], brands: [BRANDS.adidas] }),
                    },
                ],
            },
            {
                title: TYPE.shoes,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], brands: [BRANDS.nike, BRANDS.adidas, BRANDS.reebok] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.reebok,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], brands: [BRANDS.reebok] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], brands: [BRANDS.adidas] }),
                    },
                ],
            },
            {
                title: TYPE.accessories,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({
                            sex: SEX.women.name,
                            types: [TYPE.accessories],
                            brands: [BRANDS.nike, BRANDS.adidas, BRANDS.reebok],
                        }),
                    },
                    {
                        title: BRANDS.dickies,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], brands: [BRANDS.dickies] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.reebok,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], brands: [BRANDS.reebok] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], brands: [BRANDS.adidas] }),
                    },
                ],
            },
        ],
    },
    {
        title: CATEGORIES_NAMES.unisex,
        to: createQueryParams({ sex: SEX.unisex.name }),
        links: [
            {
                title: TYPE.clothes,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], brands: [BRANDS.nike, BRANDS.adidas] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], brands: [BRANDS.adidas] }),
                    },
                ],
            },
            {
                title: TYPE.shoes,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], brands: [BRANDS.nike, BRANDS.adidas, BRANDS.reebok] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.reebok,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], brands: [BRANDS.reebok] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], brands: [BRANDS.adidas] }),
                    },
                ],
            },
            {
                title: TYPE.accessories,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories] }),
                isHeader: true,
                links: [
                    {
                        title: CATEGORIES_NAMES.new,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.sale,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: CATEGORIES_NAMES.popular,
                        to: createQueryParams({
                            sex: SEX.unisex.name,
                            types: [TYPE.accessories],
                            brands: [BRANDS.nike, BRANDS.adidas, BRANDS.reebok],
                        }),
                    },
                    {
                        title: BRANDS.dickies,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], brands: [BRANDS.dickies] }),
                    },
                    {
                        title: `${BRANDS.nike}/${BRANDS.jordan}`,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], brands: [BRANDS.nike, BRANDS.jordan] }),
                    },
                    {
                        title: BRANDS.reebok,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], brands: [BRANDS.reebok] }),
                    },
                    {
                        title: BRANDS.adidas,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], brands: [BRANDS.adidas] }),
                    },
                ],
            },
        ],
    },
    {
        title: CATEGORIES_NAMES.brands,
        to: createQueryParams({}),
        links: [
            {
                title: BRANDS_BY_CATEGORY.popular.title,
                to: createQueryParams({ brands: [...Object.values(BRANDS_BY_CATEGORY.popular.brands)] }),
                isHeader: true,
                links: [
                    ...Object.keys(BRANDS_BY_CATEGORY.popular.brands).map((brandKey) => ({
                        title: BRANDS_BY_CATEGORY.popular.brands[brandKey],
                        to: createQueryParams({ brands: [BRANDS_BY_CATEGORY.popular.brands[brandKey]] }),
                    })),
                ],
            },
            {
                title: BRANDS_BY_CATEGORY.premium.title,
                to: createQueryParams({ brands: [...Object.values(BRANDS_BY_CATEGORY.premium.brands)] }),
                isHeader: true,
                links: [
                    ...Object.keys(BRANDS_BY_CATEGORY.premium.brands).map((brandKey) => ({
                        title: BRANDS_BY_CATEGORY.premium.brands[brandKey],
                        to: createQueryParams({ brands: [BRANDS_BY_CATEGORY.premium.brands[brandKey]] }),
                    })),
                ],
            },
            {
                title: BRANDS_BY_CATEGORY.street.title,
                to: createQueryParams({ brands: [...Object.values(BRANDS_BY_CATEGORY.street.brands)] }),
                isHeader: true,
                links: [
                    ...Object.keys(BRANDS_BY_CATEGORY.street.brands).map((brandKey) => ({
                        title: BRANDS_BY_CATEGORY.street.brands[brandKey],
                        to: createQueryParams({ brands: [BRANDS_BY_CATEGORY.street.brands[brandKey]] }),
                    })),
                ],
            },
        ],
    },
    {
        title: CATEGORIES_NAMES.sale,
        to: createQueryParams({ sortValue: SORT_VALUES.sale.name }),
        links: [
            {
                title: SEX.men.cases.singular,
                to: createQueryParams({ sex: SEX.men.name, sortValue: SORT_VALUES.sale.name }),
                isHeader: true,
                links: [
                    {
                        title: TYPE.shoes,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: TYPE.clothes,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: TYPE.accessories,
                        to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
                    },
                ],
            },
            {
                title: SEX.women.cases.singular,
                to: createQueryParams({ sex: SEX.women.name, sortValue: SORT_VALUES.sale.name }),
                isHeader: true,
                links: [
                    {
                        title: TYPE.shoes,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: TYPE.clothes,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: TYPE.accessories,
                        to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
                    },
                ],
            },
            {
                title: SEX.unisex.value,
                to: createQueryParams({ sex: SEX.unisex.name, sortValue: SORT_VALUES.sale.name }),
                isHeader: true,
                links: [
                    {
                        title: TYPE.shoes,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: TYPE.clothes,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
                    },
                    {
                        title: TYPE.accessories,
                        to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
                    },
                ],
            },
        ],
    },
];

export const CATEGORIES_MOBILE = [
    {
        title: TYPE.clothes,
        links: [
            {
                title: `${SEX.men.cases.singular_feminine} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes] }),
            },
            {
                title: `${SEX.women.cases.singular_feminine} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes] }),
            },
            {
                title: `${SEX.unisex.cases.singular_feminine} ${CATEGORIES_NAMES.assortment.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes] }),
            },
        ],
    },
    {
        title: TYPE.shoes,
        links: [
            {
                title: `${SEX.men.cases.singular_feminine} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes] }),
            },
            {
                title: `${SEX.women.cases.singular_feminine} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes] }),
            },
            {
                title: `${SEX.unisex.cases.singular_feminine} ${CATEGORIES_NAMES.assortment.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes] }),
            },
        ],
    },
    {
        title: TYPE.accessories,
        links: [
            {
                title: `${SEX.men.cases.plural} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories] }),
            },
            {
                title: `${SEX.women.cases.plural} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories] }),
            },
            {
                title: `${SEX.unisex.cases.singular_feminine} ${CATEGORIES_NAMES.assortment.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories] }),
            },
        ],
    },
    {
        title: CATEGORIES_NAMES.brands,
        links: Object.values(BRANDS)
            .sort((a, b) => a.localeCompare(b))
            .map((brand) => ({ title: brand, to: createQueryParams({ brands: [brand] }) })),
    },
    {
        title: CATEGORIES_NAMES.sale,
        links: [
            {
                title: `${CATEGORIES_NAMES.all[0]} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.men.cases.singular_feminine} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.women.cases.singular_feminine} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.unisex.value} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${CATEGORIES_NAMES.all[0]} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.men.cases.singular_feminine} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.women.cases.singular_feminine} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.unisex.value} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${CATEGORIES_NAMES.all[1]} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.men.cases.plural} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.women.cases.plural} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
            },
            {
                title: `${SEX.unisex.value} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], sortValue: SORT_VALUES.sale.name }),
            },
        ],
    },
    {
        title: CATEGORIES_NAMES.new,
        links: [
            {
                title: `${CATEGORIES_NAMES.all[0]} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.men.cases.singular_feminine} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.women.cases.singular_feminine} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.unisex.value} ${TYPE.shoes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.shoes], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${CATEGORIES_NAMES.all[0]} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.men.cases.singular_feminine} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.women.cases.singular_feminine} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.unisex.value} ${TYPE.clothes.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.clothes], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${CATEGORIES_NAMES.all[1]} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.men.cases.plural} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.men.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.women.cases.plural} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.women.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
            },
            {
                title: `${SEX.unisex.value} ${TYPE.accessories.toLocaleLowerCase()}`,
                to: createQueryParams({ sex: SEX.unisex.name, types: [TYPE.accessories], sortValue: SORT_VALUES.new.name }),
            },
        ],
    },
];
