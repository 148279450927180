import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';
import { itemsInitialState } from './initialStates/itemsInitialState';
import { setPermissionToRequest } from './filtersSlice';

export const getMinAndMaxPrice = createAsyncThunk('items/getMinAndMaxPrice', async (_, { dispatch }) => {
    const responseMin = await api.getMinPrice();
    const responseMax = await api.getMaxPrice();
    dispatch(setMinAndMaxPrice([responseMin[0].price, responseMax[0].price]));
});

export const addItem = createAsyncThunk('items/addItem', async ({ formData }, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.addItem(formData);
    if (response.createdAt) {
        dispatch(toggleIsFetching(false));
        dispatch(getAllItemsCount());
        return 'Товар добавлен!';
    } else {
        dispatch(toggleIsFetching(false));
        return response.message;
    }
});

export const updateItem = createAsyncThunk('items/updateItem', async ({ formData, id }, { dispatch }) => {
    dispatch(toggleIsFetchingDelete(true));
    const response = await api.updateItem(formData, id);
    if (response.createdAt) {
        dispatch(toggleIsFetchingDelete(false));
        dispatch(setItem(response));
        return 'Товар обновлен!';
    } else {
        dispatch(toggleIsFetchingDelete(false));
        return response.message;
    }
});

export const deleteImg = createAsyncThunk('items/deleteImg', async ({ imgSrc, id }, { dispatch }) => {
    const response = await api.deleteImg({ imgSrc, id });
    dispatch(setItem(response));
});

export const getAllItemsCount = createAsyncThunk('items/getAllItemsCount', async (_, { dispatch }) => {
    const response = await api.getItemsCount();
    dispatch(setAllItemsCount(response));
});

export const getFullItems = createAsyncThunk('items/getFullItems', async (sex, { dispatch }) => {
    const response = await api.getFullItems(sex);
    dispatch(setFullItems(response));
});

export const getSearchItems = createAsyncThunk('items/getSearchItems', async (value, { dispatch }) => {
    const response = await api.getSearchItems(value);
    dispatch(setSearchItems(response));
});

export const getItems = createAsyncThunk('items/getItems', async ({ sex, color, brand, size, type, sortValue, minPrice, maxPrice }, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getItems({ sex, color, brand, size, type, minPrice, maxPrice, sortValue });
    dispatch(setItems(response));
    dispatch(toggleIsFetching(false));
    dispatch(setPermissionToRequest(false));
});

export const getNextItemsPage = createAsyncThunk(
    'items/getNextItemsPage',
    async ({ sex, color, brand, size, type, minPrice, maxPrice, currentPage, sortValue }, { dispatch }) => {
        const response = await api.getNextItemsPage({ sex, color, brand, size, type, minPrice, maxPrice, page: currentPage + 1, sortValue });
        if (response.length < 1) {
            return 'Больше товаров нет';
        }
        dispatch(setCurrentPage(currentPage + 1));
        dispatch(setNextItemsPage(response));
    },
);

export const getItem = createAsyncThunk('items/getItem', async (id, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getItem(id);
    dispatch(setItem(response));
    dispatch(toggleIsFetching(false));
});

export const deleteItem = createAsyncThunk('items/deleteItem', async (id, { dispatch }) => {
    dispatch(toggleIsFetchingDelete(true));
    const response = await api.deleteItem(id);
    dispatch(setItem(response));
    dispatch(getAllItemsCount());
    dispatch(toggleIsFetchingDelete(false));
});

export const deleteSizes = createAsyncThunk('items/deleteSizes', async (id, { dispatch }) => {
    dispatch(toggleIsFetchingDelete(true));
    const response = await api.deleteSizes(id);
    dispatch(setItem(response));
    dispatch(toggleIsFetchingDelete(false));
});

export const getBasketItems = createAsyncThunk('items/getBasketItems', async (_, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getBasketItems();
    dispatch(setBasketItems(response));
    dispatch(toggleIsFetching(false));
});

export const setBasketItemsTC = createAsyncThunk('items/setBasketItemsTC', async ({ id, values }, { dispatch }) => {
    dispatch(toggleIsFetchingToBasket(true));
    const response = await api.setBasketItems(id, values);
    dispatch(setBasketItems(response));
    dispatch(toggleIsFetchingToBasket(false));
});

export const deleteItemFromBasketTC = createAsyncThunk('items/deleteItemFromBasketTC', async (id, { dispatch }) => {
    await api.deleteItemFromBasket(id);
    const basketItems = await api.getBasketItems();
    dispatch(setBasketItems(basketItems));
});

export const resetItemsFromBasketTC = createAsyncThunk('items/resetItemsFromBasketTC', async (_, { dispatch }) => {
    await api.resetItemsFromBasket();
    const basketItems = await api.getBasketItems();
    dispatch(setBasketItems(basketItems));
});

export const getFavoritesItems = createAsyncThunk('items/getFavoritesItems', async (_, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getFavoritesItems();
    dispatch(setFavoritesItems(response));
    dispatch(toggleIsFetching(false));
});

export const setFavoritesItemsTC = createAsyncThunk('items/setFavoritesItemsTC', async (itemId, { dispatch }) => {
    const response = await api.setFavoritesItems(itemId);
    dispatch(setFavoritesItems(response.items));
});

export const deleteItemFromFavoritesTC = createAsyncThunk('items/deleteItemFromFavoritesTC', async (id, { dispatch }) => {
    const response = await api.deleteItemsFromFavorites(id);
    dispatch(setFavoritesItems(response.items));
});

export const getOrders = createAsyncThunk('items/getOrders', async (_, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getOrders();
    dispatch(setAllOrders(response));
    dispatch(toggleIsFetching(false));
});

export const getHotItems = createAsyncThunk('items/getHotItems', async (_, { dispatch }) => {
    const response = await api.getHotItems();
    dispatch(setHotItems(response));
});

export const getUserOrders = createAsyncThunk('items/getUserOrders', async (_, { dispatch }) => {
    const response = await api.getUserOrders();
    dispatch(setUserOrders(response));
});

export const orderStatusUpdate = createAsyncThunk('items/orderStatusUpdate', async ({ id, status }, { dispatch }) => {
    await api.orderStatusUpdate(id, status);
    const response = await api.getOrders();
    dispatch(setAllOrders(response));
});

const itemsSlice = createSlice({
    name: 'items',
    initialState: itemsInitialState,
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },
        setFullItems: (state, action) => {
            state.fullItems = action.payload;
        },
        setSearchItems: (state, action) => {
            state.searchItems = action.payload;
        },
        setNextItemsPage: (state, action) => {
            state.items = [...state.items, ...action.payload];
        },
        setAllItemsCounter: (state, action) => {
            state.allItemsCount = action.payload;
        },
        setAllItemsCount: (state, action) => {
            state.allItemsCount = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setItem: (state, action) => {
            state.item = action.payload;
        },
        setBasketItems: (state, action) => {
            state.basketItems = action.payload;
        },
        setFavoritesItems: (state, action) => {
            state.favoritesItems = action.payload;
        },
        setHotItems: (state, action) => {
            state.hotItems = action.payload;
        },
        setAllOrders: (state, action) => {
            state.orders = action.payload;
        },
        setUserOrders: (state, action) => {
            state.userOrders = action.payload;
        },
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        toggleIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        toggleIsFetchingToBasket: (state, action) => {
            state.isFetching_toBasket = action.payload;
        },
        toggleIsFetchingDelete: (state, action) => {
            state.isFetching_delete = action.payload;
        },
        setMinAndMaxPrice: (state, action) => {
            state.minPrice = action.payload[0];
            state.maxPrice = action.payload[1];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addItem.fulfilled, () => {})
            .addCase(updateItem.fulfilled, () => {})
            .addCase(deleteImg.fulfilled, () => {})
            .addCase(getAllItemsCount.fulfilled, () => {})
            .addCase(getItems.fulfilled, () => {})
            .addCase(getFullItems.fulfilled, () => {})
            .addCase(getNextItemsPage.fulfilled, () => {})
            .addCase(getItem.fulfilled, () => {})
            .addCase(deleteItem.fulfilled, () => {})
            .addCase(deleteSizes.fulfilled, () => {})
            .addCase(getBasketItems.fulfilled, () => {})
            .addCase(setBasketItemsTC.fulfilled, () => {})
            .addCase(deleteItemFromBasketTC.fulfilled, () => {})
            .addCase(resetItemsFromBasketTC.fulfilled, () => {})
            .addCase(getFavoritesItems.fulfilled, () => {})
            .addCase(setFavoritesItemsTC.fulfilled, () => {})
            .addCase(deleteItemFromFavoritesTC.fulfilled, () => {})
            .addCase(getOrders.fulfilled, () => {})
            .addCase(getUserOrders.fulfilled, () => {})
            .addCase(orderStatusUpdate.fulfilled, () => {});
    },
});

export const {
    setItems,
    setFullItems,
    setSearchItems,
    setNextItemsPage,
    setAllItemsCounter,
    setAllItemsCount,
    setCurrentPage,
    setItem,
    setBasketItems,
    setFavoritesItems,
    setHotItems,
    setAllOrders,
    setUserOrders,
    setPageSize,
    toggleIsFetching,
    toggleIsFetchingToBasket,
    toggleIsFetchingDelete,
    setMinAndMaxPrice,
} = itemsSlice.actions;

export default itemsSlice.reducer;
