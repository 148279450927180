import { COLORS } from '../../constants';

export const Badge = ({ value, color = COLORS.red.value }) => {
    if (!value) {
        return null;
    }
    const displayCount = value > 99 ? '99+' : value;
    return (
        <div className='badge' style={{ backgroundColor: color }}>
            {displayCount}
        </div>
    );
};
