import axios from 'axios';
import { Cookies } from 'react-cookie-consent';

const token = Cookies.get('token');

const headers = token ? { Authorization: `Bearer ${token}` } : {};

const instance = axios.create({
    withCredentials: false,
    baseURL: '/api/',
    headers,
});

async function handleRequest(request) {
    try {
        const response = await request;
        return response.data;
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log('Не авторизованы');
        }
        return err.response && err.response.data;
    }
}

export const api = {
    //auth
    async getMe() {
        return handleRequest(instance.get(`users/me`));
    },
    async getUser({ email }) {
        return handleRequest(instance.get(`users/by-email?email=${email}`));
    },
    async signin({ email, password }) {
        return handleRequest(instance.post(`auth/signin`, { email, password }));
    },
    async signup(data) {
        return handleRequest(instance.post(`auth/signup`, data));
    },
    async editProfile({ values }) {
        return handleRequest(instance.post(`users/edit`, { ...values }));
    },
    async editPassword({ oldPassword, password }) {
        return handleRequest(instance.post('users/change-password', { oldPassword, password }));
    },
    //items
    async addItem(data) {
        return handleRequest(
            instance.post(`items`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
        );
    },
    async updateItem(data, id) {
        return handleRequest(instance.patch(`items/${id}`, data));
    },
    async deleteImg({ imgSrc, id }) {
        return handleRequest(instance.delete(`items/image?image=${imgSrc.split('/')[2]}&id=${id}`));
    },
    async getItems({ sex, color, brand, size, type, minPrice, maxPrice, sortValue }) {
        const params = [];

        if (sex) params.push(`sex=${encodeURIComponent(sex)}`);
        if (minPrice) params.push(`minPrice=${encodeURIComponent(minPrice)}`);
        if (maxPrice) params.push(`maxPrice=${encodeURIComponent(maxPrice)}`);
        if (sortValue) params.push(`sort=${encodeURIComponent(sortValue)}`);

        const addArrayParams = (paramName, values) => {
            if (Array.isArray(values)) {
                values.forEach((value) => {
                    params.push(`${paramName}=${encodeURIComponent(value)}`);
                });
            }
        };

        addArrayParams('color', color);
        addArrayParams('brand', brand);
        addArrayParams('size', size);
        addArrayParams('type', type);

        const queryString = params.join('&');

        return handleRequest(instance.get(`items?${queryString}`));
    },

    async getFullItems(sex) {
        return handleRequest(instance.get(`items/full?sex=${sex ? sex : ''}`));
    },
    async getSearchItems(value) {
        return handleRequest(instance.get(`items/full?name=${value}`));
    },
    async getHotItems() {
        return handleRequest(instance.get('items/hot'));
    },

    getItemsWithArrayParams(endpoint, { sex, color, brand, size, type, minPrice, maxPrice, page, sortValue }) {
        const params = [];

        if (sex) params.push(`sex=${encodeURIComponent(sex)}`);
        if (minPrice) params.push(`minPrice=${encodeURIComponent(minPrice)}`);
        if (maxPrice) params.push(`maxPrice=${encodeURIComponent(maxPrice)}`);
        if (page) params.push(`page=${encodeURIComponent(page)}`);
        if (sortValue) params.push(`sort=${encodeURIComponent(sortValue)}`);

        const addArrayParams = (paramName, values) => {
            if (Array.isArray(values)) {
                values.forEach((value) => {
                    params.push(`${paramName}=${encodeURIComponent(value)}`);
                });
            } else if (values) {
                params.push(`${paramName}=${encodeURIComponent(values)}`);
            }
        };

        addArrayParams('color', color);
        addArrayParams('brand', brand);
        addArrayParams('size', size);
        addArrayParams('type', type);

        const queryString = params.join('&');

        return handleRequest(instance.get(`${endpoint}?${queryString}`));
    },

    async getNewItems({ sex, color, brand, size, type, minPrice, maxPrice, page }) {
        return this.getItemsWithArrayParams('items/new', { sex, color, brand, size, type, minPrice, maxPrice, page });
    },

    async getSaleItems({ sex, color, brand, size, type, minPrice, maxPrice, page }) {
        return this.getItemsWithArrayParams('items/sale', { sex, color, brand, size, type, minPrice, maxPrice, page });
    },

    async getNextItemsPage({ sex, color, brand, size, type, minPrice, maxPrice, page, sortValue }) {
        return this.getItemsWithArrayParams('items', { sex, color, brand, size, type, minPrice, maxPrice, page, sortValue });
    },
    async getItemsCount() {
        return handleRequest(instance.get('items/full'));
    },

    async getItem(id) {
        return handleRequest(instance.get(`items/${id}`));
    },
    async deleteSizes(id) {
        return handleRequest(instance.patch(`items/${id}`, { sizes: [] }));
    },
    async deleteItem(id) {
        return handleRequest(instance.delete(`items/${id}`));
    },
    //orders
    async getOrders() {
        return handleRequest(instance.get(`order/all`));
    },
    async getUserOrders() {
        return handleRequest(instance.get(`order`));
    },
    async orderStatusUpdate(id, status) {
        return handleRequest(instance.patch(`order/${id}`, { updatedStatus: status }));
    },
    async getCities() {
        return handleRequest(instance.get(`order/cities`));
    },
    async setOrder(data) {
        return handleRequest(instance.post(`order`, { ...data }));
    },
    //basket
    async getBasketItems() {
        return handleRequest(instance.get('items/basket'));
    },
    async setBasketItems(id, values) {
        return handleRequest(instance.post('items/basket', { id: +id, sizes: [values.checked] }));
    },
    async deleteItemFromBasket(id) {
        return handleRequest(instance.delete(`items/basket`, { data: { id: id } }));
    },
    async resetItemsFromBasket() {
        return handleRequest(instance.delete(`items/basket`));
    },
    //favorites
    async getFavoritesItems() {
        return handleRequest(instance.get('items/favourites'));
    },
    async setFavoritesItems(id) {
        return handleRequest(instance.post('items/favourites', { id: +id }));
    },
    async deleteItemsFromFavorites(id) {
        return handleRequest(instance.delete(`items/favourites`, { data: { id: id } }));
    },
    //ad
    async getAd() {
        return handleRequest(instance.get('running-line'));
    },
    async setAd({ text }) {
        return handleRequest(instance.post('running-line', { text }));
    },
    //promo
    async getBonusPercentages() {
        return handleRequest(instance.get('promo/bonus'));
    },
    async getAllPromocodes() {
        return handleRequest(instance.get('promo/promocode/all'));
    },
    async setPromocode({ sale }) {
        return handleRequest(instance.post('promo/promocode', { sale }));
    },
    async updatePromocode({ code, status }) {
        return handleRequest(instance.patch('promo/promocode', { code, status }));
    },
    async getPromocode(code) {
        return handleRequest(instance.get(`promo/promocode?code=${code}`));
    },
    //filters
    async getMinPrice() {
        return handleRequest(instance.get('items/full?sort=asc'));
    },
    async getMaxPrice() {
        return handleRequest(instance.get('items/full?sort=desc'));
    },
};
