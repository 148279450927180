import React, {useEffect, useState, useCallback} from 'react';
import classNames from 'classnames';

const ButtonToTop = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY || 0);
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);

    const buttonClasses = classNames('buttonToTop', 'default-link', 'blackButton', 'dropShadow', {
        'displayNone': scrollY < 130
    });

    return <div className={buttonClasses} onClick={scrollToTop}></div>
};

export default ButtonToTop;