import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';
import { clientInitialState } from './initialStates/clientInitialState';

export const getUser = createAsyncThunk('client/getUser', async ({ email }, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getUser({ email });
    dispatch(toggleIsFetching(false));
    if (response.id && response.statusCode !== 500) {
        dispatch(setUser(response));
        return response;
    }

    return 'Пользователь не найден...';
});

const clientSlice = createSlice({
    name: 'client',
    initialState: clientInitialState,
    reducers: {
        setUser: (state, action) => {
            state.city = action.payload.city;
            state.email = action.payload.email;
            state.role = action.payload.role;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.id = action.payload.id;

            state.phoneNumber = action.payload.phoneNumber;
            state.pointOfIssue = action.payload.pointOfIssue;
            state.amountOfPurchases = action.payload.amountOfPurchases;
            state.personalSale = action.payload.personalSale;
        },
        toggleIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
    },
});

export const { setUser, toggleIsFetching } = clientSlice.actions;

export default clientSlice.reducer;
