import { EMOJI } from '../emoji';

export const FIND_US = {
    title: `Как нас найти? ${EMOJI.face.thinking[0]}`,
    items: [
        {
            subtitle: 'Наш магазин располагается в самом центре Нижнего Новгорода на площади Максима Горького! Точный адрес для навигатора:',
        },
        {
            title: 'Площадь Максима Горького 4/2',
        },
        {
            subtitle:
                'Если вы идете со стороны Макдональдса с ул. Большая Покровская, вам необходимо идти в сторону Сбербанка, перейти дорогу, пройти мимо аптеки и сразу спуститься в цоколь.',
        },
        {
            subtitle:
                'Для тех, кто идет с противоположной стороны - пройдите Сбербанк и Компьютерный клуб КиберПрайд, потом налево и сразу спуститесь в цоколь.',
        },
        {
            title: 'Для клиентов с личным автомобилем',
            subtitle:
                'Специально для Вас у нашего здания есть бесплатная парковка во дворе. Вам необходимо выехать на ул. Маслякова, проехать в сторону площади Горького и свернуть направо во двор, как показано на картинке. Затем пройти немного пешком в сторону площади и спуститься в цоколь.',
        },
        {
            subtitle: 'Координаты парковки для навигаторов:',
        },
        {
            title: '56.314586, 43.989593',
        },
    ],
    lastText: 'Удачных покупок!',
};
