export const EMOJI = {
    face: {
        sad: ['🥺', '😢', '😬', '🤕'],
        happy: ['😊'],
        thinking: ['🤔'],
    },
    human: ['🙋', '👤'],
    animals: ['🙈'],
    docs: ['📄', '📂', '📖'],
    cars: ['🚚'],
    money: ['💰'],
    mail: ['📫'],
    basket: ['🛒'],
    hearts: ['❤️'],
    cloth: ['👟'],
    ad: ['📣'],
    win: ['🏆'],
    waiting: ['⏳'],
    promocodes: ['🏷'],
    gift: ['🎁']
};
