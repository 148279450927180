import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import Auth from '../Profile/Auth/Auth';
import Registration from '../Profile/Registration/Registration';
import { UserIconSVG } from '../../svg';

const CustomModal = ({ text }) => {
    const [value, setValue] = useState(false);

    const toggleValueAuth = () => {
        return setValue(!value);
    };

    return (
        <Popup
            trigger={
                text ? (
                    <p className={`header__nav-button ${text === 'Авторизация' && 'button default-link action'}`}>{text}</p>
                ) : (
                    <span>
                        <UserIconSVG />
                    </span>
                )
            }
            modal
            nested
        >
            {(close) => (
                <div className='modal dropShadow'>
                    <button className='close' onClick={close}>
                        &times;
                    </button>
                    <div className='header_modal'>Войдите или зарегистрируйтесь, чтобы продолжить</div>
                    <div className='content'>
                        {value ? <Registration toggleValueAuth={toggleValueAuth} /> : <Auth toggleValueAuth={toggleValueAuth} />}
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default CustomModal;
