import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { getBasketItems, getFavoritesItems } from '../../redux/ItemsReducer';

import SearchPopup from '../Search/SearchPopup';
import { HeaderLogo } from './HeaderLogo';
import { HeaderContacts } from './HeaderContacts';
import { HeaderCategoriesDesktop } from './HeaderCategoriesDesktop';
import { HeaderActions } from './HeaderActions';
import { HeaderBurger } from './HeaderBurger';
import { HeaderMobileMenu } from './HeaderMobileMenu';
import { useShowNewYearDecor } from '../../hooks';

const Header = () => {
    const dispatch = useDispatch();

    const isShowNewYearDecor = useShowNewYearDecor();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([dispatch(getBasketItems()), dispatch(getFavoritesItems())]);

                console.log('Данные успешно загружены');
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            }
        };

        fetchData();
    }, [dispatch]);

    return (
        <header className='header'>
            <div className='header__blur'>
                {isShowNewYearDecor && <div className='newYear__lights wh100'></div>}
                <div className='wrapper'>
                    <div className='header__wrapper flex'>
                        <div className='flex wh100'>
                            <div className='header__logo flex'>
                                <HeaderLogo />
                                <HeaderContacts />
                            </div>
                            <HeaderCategoriesDesktop />
                            <HeaderActions />

                            <div className='flex gap-2 header__right-wrapper'>
                                <span className='header__nav-link header__right-search'>
                                    <SearchPopup />
                                </span>
                                <HeaderBurger />
                            </div>
                        </div>

                        <HeaderMobileMenu />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
