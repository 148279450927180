import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';
import { orderInitialState } from './initialStates/orderInitialState';
import { applyPromocode } from './PromoCodeReducer';

export const getCities = createAsyncThunk('order/getCities', async () => {
    const response = await api.getCities();
    return response;
});

export const setOrder = createAsyncThunk('order/setOrder', async ({ data }, { dispatch }) => {
    const response = await api.setOrder(data);
    dispatch(applyPromocode(''));
    return response;
});

const orderSlice = createSlice({
    name: 'order',
    initialState: orderInitialState,
    reducers: {
        setPersonalData: (state, action) => {
            state.email = action.payload.email;
            state.phoneNumber = action.payload.phoneNumber;
            state.fullName = action.payload.fullName;
        },
        setCity: (state, action) => {
            state.city = action.payload.city;
            state.pointOfIssue = action.payload.pointOfIssue;
        },
        setCities: (state, action) => {
            state.cities = action.payload;
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCities.fulfilled, (state, action) => {
            state.cities = action.payload;
        });
        builder.addCase(setOrder.fulfilled, () => {});
    },
});

export const { setPersonalData, setCity, setCities, setProducts } = orderSlice.actions;

export default orderSlice.reducer;
