export const SORT_VALUES = {
    default: {
        name: 'default',
        value: 'умолчанию',
    },
    asc: {
        name: 'asc',
        value: 'возрастанию цены',
    },
    desc: {
        name: 'desc',
        value: 'убыванию цены',
    },
    sale: {
        name: 'sale',
        value: 'размеру скидки',
    },
    new: {
        name: 'new',
        value: 'сначала новые',
    },
};
