import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { appInitialState } from './initialStates/appInitialState';
import { api } from '../api';

export const getAd = createAsyncThunk('app/getAd', async (_, { dispatch }) => {
    const response = await api.getAd();
    dispatch(setInfo(response.text));
});

export const getBonusPercentages = createAsyncThunk('app/getBonusPercentages', async (_, { dispatch }) => {
    const response = await api.getBonusPercentages();
    dispatch(setBonusPercentages(response));
});

export const setAdText = createAsyncThunk('app/setAdText', async ({ text }, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.setAd({ text });
    dispatch(setInfo(response.text));
    dispatch(toggleIsFetching(false));
});

const appSlice = createSlice({
    name: 'app',
    initialState: appInitialState,
    reducers: {
        setInitialized: (state) => {
            state.initialized = true;
        },
        setInfo: (state, action) => {
            state.info = action.payload;
        },
        setBonusPercentages: (state, action) => {
            state.bonusPercentages = action.payload;
        },
        setModalImg: (state, action) => {
            state.modalImg = action.payload;
        },
        closeModalImg: (state) => {
            state.modalImg = '';
        },
        toggleIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        openMobileMenu: (state) => {
            state.isMobileMenuOpen = true;
        },
        closeMobileMenu: (state) => {
            state.isMobileMenuOpen = false;
        },
    },
});

export const { setInitialized, setInfo, setModalImg, closeModalImg, toggleIsFetching, setBonusPercentages, openMobileMenu, closeMobileMenu } =
    appSlice.actions;

export default appSlice.reducer;
