import '../index.css';

export const getButtonStyle = (variant) => {
    let text;
    let bg;
    let opacity;
    const border = '2px solid';
    let borderColor = 'var(--lightgrey)';
    switch (variant) {
        case 'green':
            text = 'var(--white)';
            bg = 'var(--green)';
            borderColor = 'var(--green)';
            break;
        case 'red':
            text = 'var(--white)';
            bg = 'var(--red)';
            borderColor = 'var(--red)';
            break;
        case 'yellow':
            text = 'var(--black)';
            bg = 'var(--yellow)';
            borderColor = 'var(--yellow)';
            break;
        case 'black':
            text = 'var(--white)';
            bg = 'var(--black)';
            borderColor = 'var(--black)';
            break;
        case 'white':
            text = 'var(--black)';
            bg = 'var(--white)';
            borderColor = 'var(--lightgrey)';
            break;
        case 'blue':
            text = 'var(--white)';
            bg = 'var(--blue)';
            borderColor = 'var(--blue)';
            break;
        case 'grey':
            text = 'var(--white)';
            bg = 'var(--grey)';
            borderColor = 'var(--grey)';
            break;
        case 'disabled':
            opacity = '0.6';
            break;
        default:
            break;
    }
    return { color: text, backgroundColor: bg, opacity: opacity, border: border, borderColor: borderColor };
};
