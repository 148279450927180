import { motion } from 'framer-motion';

export const Animation = ({ children, speed = 1, y = 0, x = 0, className, pulse }) => {
    const variants = {
        hidden: { y: y, x: x, opacity: 0 },
        visible: (speed) => ({
            y: 0,
            x: 0,
            opacity: 1,
            transition: { delay: speed * 0.2 },
        }),
    };

    const pulseAnimation = pulse
        ? {
              opacity: [1, 0.2, 1],
              transition: {
                  duration: 2.5,
                  repeat: Infinity,
                  ease: 'easeInOut',
              },
          }
        : {};

    return (
        <motion.div initial='hidden' animate='visible' variants={variants} custom={speed} className={className} whileInView={pulseAnimation}>
            {children}
        </motion.div>
    );
};
