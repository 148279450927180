import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../commons/Preloader/Preloader';
import { Button } from '../../commons/Buttons/Button';
import { FormField } from '../../commons/Forms/FormFiled';
import { useDispatch, useSelector } from 'react-redux';
import { signUp } from '../../../redux/authReducer';

const validationSchema = Yup.object({
    email: Yup.string()
        .required('Поле email обязательно')
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Некорректный email адрес'),
    password: Yup.string().required('Поле пароль обязательно').min(8, 'Поле пароль должно быть больше 8 символов'),
    password_confirm: Yup.string()
        .required('Поле подтверждения пароля обязательно')
        .oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
    firstName: Yup.string()
        .matches(/^[a-zа-яё\s\-']+$/iu, 'Некорректное имя')
        .min(8, 'Слишком короткое имя'),
});

const Registration = ({ toggleValueAuth }) => {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthFetching = useSelector((state) => state.auth.isAuthFetching);

    return (
        <div className='form__wrapper flexColumn wh100'>
            <h2 className='form__title'>Регистрация</h2>
            <span className='input__error'>{error}</span>
            {isAuthFetching ? (
                <Preloader />
            ) : (
                <Formik
                    initialValues={{ email: '', password: '', password_confirm: '', firstName: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        dispatch(signUp(values)).then((response) => {
                            if (response.payload.access_token) {
                                navigate('/profile');
                                navigate(0);
                            }
                            setError(response.payload);
                        });
                        setSubmitting(false);
                    }}
                >
                    {({ handleSubmit, isSubmitting, errors, values, touched }) => (
                        <form onSubmit={handleSubmit} className='form flexColumn wh100'>
                            <FormField name='email' type='email' label='Email' touched={touched} errors={errors} required />
                            <FormField name='password' type='password' label='Пароль' touched={touched} errors={errors} required />
                            <FormField name='password_confirm' type='password' label='Повторите пароль' touched={touched} errors={errors} required />
                            <FormField name='firstName' type='text' label='Ваше имя' touched={touched} errors={errors} />
                            <span className='required_field'>* - обязательное поле</span>
                            <span onClick={toggleValueAuth} className='input__link default-link'>
                                Уже есть аккаунт? Войдите
                            </span>
                            <Button
                                type='submit'
                                disabled={
                                    isSubmitting ||
                                    !!(errors.email || errors.firstName || errors.password || errors.password_confirm) ||
                                    !!(!values.email || !values.password || !values.password_confirm)
                                }
                                variant='blue'
                            >
                                Создать аккаунт
                            </Button>
                        </form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default Registration;
