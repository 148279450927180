export const SEX = {
    men: {
        name: 'men',
        value: 'Мужской',
        cases: {
            nominative: 'Мужской',
            genitive: 'Мужского',
            dative: 'Мужскому',
            accusative: 'Мужской',
            instrumental: 'Мужским',
            prepositional: 'Мужском',
            singular: 'Мужское',
            singular_feminine: 'Мужская',
            plural: 'Мужские',
            plural_genitive: 'Мужчин',
            plural_dative: 'Мужчинам',
            plural_instrumental: 'Мужчинами',
            plural_prepositional: 'Мужчинах',
        },
    },
    women: {
        name: 'women',
        value: 'Женский',
        cases: {
            nominative: 'Женский',
            genitive: 'Женского',
            dative: 'Женскому',
            accusative: 'Женский',
            instrumental: 'Женским',
            prepositional: 'Женском',
            singular: 'Женское',
            singular_feminine: 'Женская',
            plural: 'Женские',
            plural_genitive: 'Женщин',
            plural_dative: 'Женщинам',
            plural_instrumental: 'Женщинами',
            plural_prepositional: 'Женщинах',
        },
    },
    unisex: {
        name: 'unisex',
        value: 'Унисекс',
        cases: {
            nominative: 'Унисекс',
            genitive: 'Унисекса',
            dative: 'Унисексу',
            accusative: 'Унисекс',
            instrumental: 'Унисексом',
            prepositional: 'Унисексе',
            singular: 'Унисекс',
            singular_feminine: 'Унисекс',
            plural: 'Унисекс',
        },
    },
};
