import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../commons/Preloader/Preloader';
import { Button } from '../../commons/Buttons/Button';
import { FormField } from '../../commons/Forms/FormFiled';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../../redux/authReducer';

const validationSchema = Yup.object({
    email: Yup.string()
        .required('Поле email обязательно')
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Некорректный email адрес'),
    password: Yup.string().required('Поле Пароль обязательно').min(8, 'Поле пароль должно быть больше 8 символов'),
});

const Auth = ({ toggleValueAuth }) => {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthFetching = useSelector((state) => state.auth.isAuthFetching);

    return (
        <div className='form__wrapper flexColumn wh100'>
            <h2 className='form__title'>Авторизация</h2>
            <span className='input__error'>{error}</span>
            {isAuthFetching ? (
                <Preloader />
            ) : (
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        dispatch(signIn(values)).then((response) => {
                            if (response.payload.access_token) {
                                navigate('/profile');
                                navigate(0);
                            }
                            setError(response.payload);
                        });
                        setSubmitting(false);
                    }}
                >
                    {({ handleSubmit, isSubmitting, errors, values, touched }) => (
                        <form onSubmit={handleSubmit} className='form flexColumn wh100'>
                            <FormField name='email' type='email' label='Email' touched={touched} errors={errors} required />
                            <FormField name='password' type='password' label='Пароль' touched={touched} errors={errors} required />
                            <span className='required_field'>* - обязательное поле</span>
                            <span onClick={toggleValueAuth} className='input__link default-link'>
                                Нет аккаунта? Зарегистрируйтесь
                            </span>
                            <Button
                                type='submit'
                                disabled={isSubmitting || !!(!values.email || !values.password) || !!(errors.email || errors.password)}
                                variant='green'
                            >
                                Войти
                            </Button>
                        </form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default Auth;
