export const STATUS_COLORS = {
    Отменён: 'gray',
    Оплачено: 'green',
    Собирается: 'tiffany',
    'Передано в доставку': 'orange',
    'В пути': 'blue',
    'Прибыло в пункт выдачи': 'purple',
    Получено: 'green',
    Возврат: 'red',
};
