import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { getImageUrl, getSex, truncateString } from '../../utils';
import del from '../../img/del.svg';
import { getSearchItems } from '../../redux/ItemsReducer';
import Preloader from '../commons/Preloader/Preloader';

const Search = ({ onClose }) => {
    const [value, setValue] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const searchItems = useSelector((state) => state.items.searchItems);

    const [isLittle, setIsLittle] = useState(window.innerWidth <= 490);

    useEffect(() => {
        const handleResize = () => {
            setIsLittle(window.innerWidth <= 490);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const node = useRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (node.current.contains(e.target)) {
                return;
            }
            setIsOpen(false);
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    let timeout;

    const onChange = (e) => {
        setIsFetching(true);
        setValue(e.target.value);

        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            dispatch(getSearchItems(e.target.value)).then(() => {
                setIsFetching(false);
            });
        }, 700);
    };

    const itemClickHandler = (e) => {
        setValue(e.target.textContent);
        dispatch(getSearchItems(e.target.textContent));
        setIsOpen(!isOpen);
        onClose && onClose();
    };

    const inputClickHandler = () => {
        setIsOpen(true);
    };

    const clearInputHandler = () => {
        dispatch(getSearchItems());
        setValue('');
    };

    return (
        <div className='search' ref={node} style={{ position: 'relative ' }}>
            <input type='text' placeholder='Поиск...' value={value} onChange={onChange} onClick={inputClickHandler} />
            {value && (
                <span onClick={clearInputHandler} style={{ position: 'absolute', right: 18, top: 15, cursor: 'pointer' }}>
                    <img src={del} alt='Удалить' />
                </span>
            )}
            <ul className='search__autocomplete wh100'>
                {value && isOpen ? (
                    isFetching ? (
                        <div className='flex' style={{ justifyContent: 'center', margin: '5px' }}>
                            <Preloader size='40' />
                        </div>
                    ) : searchItems && searchItems.length > 0 ? (
                        searchItems.map((product) => {
                            return (
                                <NavLink
                                    className='search__autocomplete-item gap-1'
                                    onClick={itemClickHandler}
                                    to={`products/${getSex(product.sex)}/${product.id}`}
                                >
                                    <img src={getImageUrl(product.img[0])} alt='' style={{ width: '40px', height: '40px', borderRadius: '8px' }} />
                                    <span>{truncateString(product.name, isLittle ? '27' : '40')}</span>
                                </NavLink>
                            );
                        })
                    ) : (
                        <p
                            className='search__autocomplete-item flex gap-1'
                            style={{ cursor: 'not-allowed', padding: '20px', fontSize: isLittle && '0.8rem' }}
                        >
                            <span style={{ fontSize: '25px' }}>🥺</span>К сожалению, ничего не найдено
                        </p>
                    )
                ) : null}
            </ul>
        </div>
    );
};

export default Search;
