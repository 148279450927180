import { EMOJI } from '../emoji';

export const PAYMENT = {
    title: `Оплата ${EMOJI.money[0]}`,
    items: [
        {
            title: 'Наличными',
            subtitle:
                'Оплата принимается в российских рублях в наших магазинах или курьером при получении товара после проверки комплектности и внешнего вида изделия.',
        },
        {
            title: 'Банковской картой',
            subtitle:
                'При помощи банковской карты вы можете оплатить ваш заказ в магазине розничной сети и в интернет-магазине. Данный способ расчета не влияет на стоимость товара - комиссия при оплате заказа картой не взимается. Мы принимаем пластиковые карты международных платежных систем МИР, VISA, MasterCard и т.д.',
        },
        {
            title: 'Оплата по QR коду через СБП',
            subtitle:
                'При помощи своего телефона с установленным приложением банка-участника вы можете оплатить вашу покупку в магазине розничной сети. Данный способ расчета не влияет на стоимость товара - комиссия при оплате не взимается.',
        },
    ],
};
