import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Marquee from 'react-fast-marquee';
import { getAd } from '../../redux/AppReducer';

export const InfoBlock = () => {
    const info = useSelector((state) => state.app.info);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAd());
    }, [dispatch]);

    if (!info) {
        return null;
    }

    return (
        <Marquee className='infoBlock' pauseOnHover autoFill speed={30}>
            <p style={{ margin: '0 25px' }}>{info}</p>
        </Marquee>
    );
};
