import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../api';
import { Cookies } from 'react-cookie-consent';
import { authInitialState } from './initialStates/authInitialState';

export const getMe = createAsyncThunk('auth/getMe', async (_, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.getMe();
    dispatch(toggleIsFetching(false));
    if (response.statusCode === 401) {
        dispatch(setIsAuth(false));
        return response;
    } else {
        dispatch(setIsAuth(true));
        dispatch(setUser(response));
        return response;
    }
});

export const editProfile = createAsyncThunk('auth/editProfile', async ({ values }, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.editProfile({ values });
    dispatch(toggleIsFetching(false));
    if (response.email) {
        dispatch(setUser(response));
        return response;
    }
    return 'Ошибка';
});

export const editPassword = createAsyncThunk('auth/editPassword', async ({ oldPassword, password }, { dispatch }) => {
    dispatch(toggleIsFetching(true));
    const response = await api.editPassword({ oldPassword, password });
    dispatch(toggleIsFetching(false));
    if (response) {
        return response;
    }
    return response.message;
});


export const signIn = createAsyncThunk('auth/signIn', async ({ email, password }, { dispatch }) => {
    const response = await api.signin({ email, password });
    if (response.access_token) {
        Cookies.set('token', response.access_token, { expires: 30, secure: true });
        dispatch(setIsAuth(true));
        return response;
    } else if (response.statusCode === 403) {
        dispatch(setIsAuth(false));
        return 'Проверьте правильность данных!';
    } else {
        dispatch(setIsAuth(false));
        return response;
    }
});

export const signUp = createAsyncThunk('auth/signUp', async ({ email, password, firstName }, { dispatch }) => {
    const data = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: '',
        phoneNumber: '',
        city: '',
        pointOfIssue: '',
    };
    const response = await api.signup(data);
    if (response.access_token) {
        Cookies.set('token', response.access_token, { expires: 30, secure: true });
        dispatch(setIsAuth(true));
        return response;
    } else if (response.statusCode === 403) {
        dispatch(setIsAuth(false));
        return 'Аккаунт с такими данными уже существует!';
    } else {
        dispatch(setIsAuth(false));
        return response;
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState: authInitialState,
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        setUser: (state, action) => {
            state.city = action.payload.city;
            state.email = action.payload.email;
            state.role = action.payload.role;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.id = action.payload.id;

            state.phoneNumber = action.payload.phoneNumber;
            state.pointOfIssue = action.payload.pointOfIssue;
        },
        logOut: (state) => {
            Cookies.remove('token');
            state.isAuth = false;
        },
        toggleIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMe.pending, (state) => {
                state.isAuthFetching = true;
            })
            .addCase(getMe.fulfilled, (state, action) => {
                state.isAuthFetching = false;
                Object.assign(state, action.payload);
            })
            .addCase(signIn.pending, (state) => {
                state.isAuthFetching = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.isAuthFetching = false;
                Object.assign(state, action.payload);
            })
            .addCase(signUp.pending, (state) => {
                state.isAuthFetching = true;
            })
            .addCase(signUp.fulfilled, (state, action) => {
                state.isAuthFetching = false;
                Object.assign(state, action.payload);
            });
    },
});

export const { setIsAuth, logOut, toggleIsFetching, setUser } = authSlice.actions;

export default authSlice.reducer;
