import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Button } from '../Buttons/Button';

export const CookieWarning = () => {
    const [isHidden, setIsHidden] = useState(localStorage.getItem('cookieAccepted') === 'true');

    const handleOkClick = useCallback(() => {
        setIsHidden(true);
    }, []);

    useEffect(() => {
        localStorage.setItem('cookieAccepted', String(isHidden));
    }, [isHidden]);

    const cookieClasses = classNames('cookie', {
        displayNone: isHidden,
    });

    return (
        <div className={cookieClasses} style={{ gap: '10px' }}>
            <span>Этот сайт использует файлы cookie</span>
            <div className='flex' style={{ gap: '10px' }}>
                <Button isLink href='/cookie' variant='white' isLittle>
                    Подробнее
                </Button>
                <Button onClick={handleOkClick} variant='green' isLittle>
                    Принять
                </Button>
            </div>
        </div>
    );
};
