export const itemsInitialState = {
    items: [],
    hotItems: [],
    searchItems: [],
    fullItems: [],
    allItemsCount: 0,
    item: [],
    basketItems: [],
    favoritesItems: [],
    pageSize: 8,
    currentPage: 1,
    totalItemsCount: 0,
    orders: [],
    minPrice: null,
    maxPrice: null,
    userOrders: [],
    isFetching: false,
    isFetching_toBasket: false,
    isFetching_toFavorites: false,
    isFetching_delete: false,
};
