export const VersionsPage = {
    title: 'История версий сайта',
    pageInfo: [
        {
            version: '1.0.0',
            date: '27.02.2023',
            versionBlock: [
                {
                    title: 'Базовые возможности',
                    description: [
                        'Предусмотрен просмотр всех или отдельных товаров',
                        'Реализовано добавление товара в корзину',
                        'Реализовано добавление товара в избранное',
                        'Реализована авторизация и регистрация',
                        'Предусмотрено посещение полезных ссылок',
                        'Реализовано оформление и оплата заказов',
                        'Реализовано отслеживание заказов',
                    ],
                },
            ],
        },
        {
            version: '1.0.1',
            date: '17.03.2023',
            versionBlock: [
                {
                    title: 'Мелкие исправления',
                    description: [
                        'Осуществлен переход на HTTPS безопасный протокол',
                        'Исправлено отображение размеров в карточке товара - теперь там только уникальные размеры без дублирования. Также добавлено ограничение размера строки. Чтобы посмотреть все актуальные размеры - нужно перейти на страницу нужного товара путем нажатия на название или картинку',
                    ],
                },
            ],
        },
        {
            version: '1.0.2',
            date: '01.04.2023',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Удалены лишние отступы в карусели "Горячие предложения" на главной странице',
                        'Удален счетчик количества вещей в каждой категории пола',
                        'Внесены другие мелкие исправления',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Добавлена возможность подгружать следующую страницу с товарами (до этого был лимит 20 товаров на одной странице в одной категории)',
                        'Добавлен счетчик всех уникальных вещей магазина в шапку сайта',
                    ],
                },
            ],
        },
        {
            version: '1.0.3',
            date: '08.07.2023',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Исправлено некорректное отображение товаров в категории "Избранное"',
                        'Исправлен визуальный баг карточки товара в Safari Теперь отображается красивая тень',
                        'Размер скидки в процентах и кнопка "В избранное" перенесены для удобства чуть выше',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Добавлены плавные анимации некоторых элементов на сайте',
                        'Добавлен фильтр товаров по типу (Одежда, Обувь, Аксессуары)',
                        'Добавлена кнопка "Наверх"',
                        'Добавлены скругления для карты и прочих элементов',
                    ],
                },
            ],
        },
        {
            version: '1.0.4',
            date: '20.07.2023',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Устранены ошибки на некоторых страницах сайта, из-за которых не открывался контент',
                        'Устранен баг, при котором на главной странице в блоке "Горячие предложения" отображались только товары из первой двадцатки товаров',
                        'Устранен баг, когда при поиске товаров - выходили результаты только с прогруженных товаров Теперь поиск идет по всему ассортименту магазина (Учитываются так же фильтры и сортировки)',
                        'Исправлен путь ссылки "Унисекс ассортимент" в подвале сайта. Раньше ошибочно вел на страницу с женским товаром',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: ['Добавлен счетчик всех товаров в категориях "Мужское / Женское / Унисекс"'],
                },
            ],
        },
        {
            version: '1.0.5',
            date: '29.07.2023',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Устранена ошибка, когда при закрытии вкладки с нашим сайтом - приходилось авторизироваться заново',
                        'Устранены некоторые ошибки с полями ввода в формах "Авторизация", "Регистрация" и "Заказ"',
                        'Исправлено отображение страницы "Корзина" в мобильной версии',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Ссылка на страницу с товарами стала доступна в мобильном меню',
                        'Переработана мобильная версия страницы с товарами - теперь воспринимается намного лучше, благодаря разделению на 2 колонки',
                        'Много других мобильных изменений (Мельче шрифты, отступы меньше и так далее)',
                    ],
                },
            ],
        },
        {
            version: '1.1.0',
            date: '01.08.2023',
            versionBlock: [
                {
                    title: 'Улучшения',
                    description: [
                        'Переработана карточка товара в мобильной версии: Размер скидки, Отметка о новизне товара и кнопка "Добавить в избранное" - стали намного меньше по размеру для удобства восприятия',
                        'Добавление блока Cookie и страницы "подробнее о cookie" на сайт',
                        'Некоторые визуальные изменения для модератора контента',
                        'Добавление/обновление документов: "Политика конфиденциальности" и "Правила пользования сайтом"',
                    ],
                },
            ],
        },
        {
            version: '1.1.1',
            date: '14.08.2023',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Устранена ошибка, когда в профиле не отображался сохраненный пункт выдачи',
                        'Устранена ошибка авторизации, когда нужно было полностью обновлять страницу после входа, чтобы пользоваться сайтом',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: ['Дополнительно добавлен режим работы в подвал сайта'],
                },
            ],
        },
        {
            version: '1.1.2',
            date: '25.11.2023',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: ['Устранена проблема с обновлением состояния сортировки при загрузке новой страницы товаров'],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Реализована система фильтрации товаров на главной странице. Теперь пользователи могут задавать параметры по цвету, размеру, бренду и диапазону цен для получения подходящих вариантов',
                        'Добавлены новые бренды: Vans, Timberland, Dr Martens, Asics',
                    ],
                },
            ],
        },
        {
            version: '1.1.3',
            date: '02.12.2023',
            versionBlock: [
                {
                    title: 'Улучшения',
                    description: [
                        'Внедрена страница "Под заказ", где пользователи могут ознакомиться с правилами индивидуальной доставки товара и начать диалог с магазином',
                        'Обновлен контактный номер телефона и режим работы',
                        'Добавлен раздел "Отзывы" на главную страницу, а также ссылка на полноценную страницу "Отзывы" в подвале сайта. Интерфейс представлен в виде удобной карусели с возможностью перехода к источнику отзыва',
                    ],
                },
            ],
        },
        {
            version: '1.1.4',
            date: '04.12.2023',
            versionBlock: [
                {
                    title: 'Улучшения',
                    description: [
                        'Добавлены новые бренды: Stone Island, Levis, Native Fitzsimmons, Converse',
                        'Добавлены новые цвета: Бежевый, Оранжевый',
                        'Доработана логика отображения изображений на сайте',
                    ],
                },
            ],
        },
        {
            version: '1.2.0',
            date: '04.01.2024',
            versionBlock: [
                {
                    title: 'Улучшения',
                    description: [
                        'Оптимизирована работа сайта, увеличив производительность на 30%',
                        'Обновлены стили для всех кнопок на сайте - более приятный дизайн и цвета',
                        'Обновлено модальное окно заказа товара - более понятная навигация по этапам и замена некоторых стилей',
                        'Обновлены ссылки на разделы товаров по полу. Стали более приятные кнопки',
                        'Переработаны пути до товаров. Теперь ссылка на товар учитывает и пол, например было .../products/100, а стало .../products/men/100 - это означает, что товар находится в мужской категории. Это так же помогает понимать где находится пользователь, потому что ссылки на разделы теперь так же это подсказывают. Ранее при переходе на товар - ссылка на раздел переставала подсвечиваться',
                    ],
                },
                {
                    title: 'Исправления',
                    description: [
                        'Исправлена проблема, когда при нажатии на сброс фильтров товары не обновлялись',
                        'Устранена проблема с бесконечной загрузкой на страницах "Избранное" и "Корзина". Если пользователь не был авторизован, страница загружалась до тех пор, пока не перезагрузить страницу',
                        'Исправлена проблема с индикатором загрузки внутри товара, когда при переходе на страницу существующий товар пару секунд не отображался, а потом резко появлялся',
                        'Исправлена проблема, когда при загрузке всех товаров в определенном отделе, например, в женском, при переходе далее на мужской или унисекс - кнопка "Загрузить еще" была неактивна и имела надпись "Товаров больше нет..."',
                        'Исправлена валидация формы заказа товара. Теперь все работает как надо, без ошибок. Ранее можно было нажать на кнопку "Далее" и перейти на следующий этап даже не смотря на то, что текущие поля были не заполнены или содержали ошибки',
                    ],
                },
            ],
        },
        {
            version: '1.3.0',
            date: '06.01.2024',
            versionBlock: [
                {
                    title: 'Улучшения',
                    description: [
                        'Оптимизирована работа сайта, увеличив производительность на 10%',
                        'Полная замена дизайна и логики работы для всех форм на сайте. Поля стали намного более приятные внешне, плюс обрели проверку на введенные значния, теперь грамотно отображают ошибки. Формы стали делать подсказки пользователю и показывать различные ошибки',
                        'Отдельно выделим форму оформления заказа. Полная переработка подсказки этапов + все вышеперечисленные улучшения',
                        'Полная переработка поиска на сайте. Теперь он вынесен на уровень выше и работает не в своих разделах по полу, а на всем сайте. Теперь можно искать не только по названию товара, а еще и по артикулу. Подсказки товаров, которые выводятся снизу поиска теперь обрели фото, а так же при клике на них переносят на сам товар',
                        'Большая переработка интерфейсов для администратора сайта и менеджера',
                    ],
                },
                {
                    title: 'Исправления',
                    description: [
                        'Исправлена ошибка, когда при добавлении товара в корзину, общий ценник корзины в шапке сайта менялся не сразу',
                        'Большие исправления различных ошибок в работе системных интерфейсов для администратора сайта и менеджера',
                    ],
                },
            ],
        },
        {
            version: '1.3.1',
            date: '10.01.2024',
            versionBlock: [
                {
                    title: 'Улучшения',
                    description: ['Оптимизирована работа сайта, увеличив производительность на 5%'],
                },
                {
                    title: 'Исправления',
                    description: [
                        'Исправлена ошибка, когда кнопка "Показать еще" в разделе товаров показывала "Товаров больше нет" в разных сценариях, когда товары еще могли быть загружены',
                    ],
                },
            ],
        },
        {
            version: '1.3.2',
            date: '16.01.2024',
            versionBlock: [
                {
                    title: 'Улучшения',
                    description: [
                        'Смена цветовой палитры в документах сайта и на страницах с информацией. Отказ от красного акцента и переход на черно-белый вариант отображения текста',
                    ],
                },
            ],
        },
        {
            version: '1.4.0',
            date: '21.01.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: ['Исправлена ссылка на странице "Под заказ" которая ведет в телеграм'],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Добавлен блок "Объявление" в самом верху сайта. Теперь тут будет публиковаться важная информация о сайте или магазине',
                        'Добавлена возможность менять личную информацию в профиле (имя, телефон, почту, город и пункт выдачи)',
                        'Добавлена возможность менять пароль',
                        'Добавлена кнопка "Заказать" - внутри товаров, которые отсутвуют в продаже или у которых нет нужного размера в наличии',
                        'Добавлена форма для запроса нужной вещи под заказ, которая на данный момент отсутствует в продаже',
                    ],
                },
            ],
        },
        {
            version: '1.4.1',
            date: '27.01.2024',
            versionBlock: [
                {
                    title: 'Улучшения',
                    description: [
                        'Обновлен поиск по сайту. Теперь работает быстрее. Также добвлен индикатор загрузки товаров для улучшения пользовательского опыта',
                        'Обновлен индикатор загрузки. Теперь более нейтральный по цвету и форме',
                    ],
                },
            ],
        },
        {
            version: '1.4.2',
            date: '04.02.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Исправлена пагинация. Подгрузка новых страниц ошибочно сохраняла значение текущей страницы и при выборе фильтров или режима сортировки - не сбрасывалась, тем самым пропускала некоторые товары',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Обновлена функция сортировки вещей. Раньше было много проблем с отображением, сейчас работает как нужно. Так же обновлен переключатель режима сортировки, теперь - модальное окно',
                        'Обновлены кнопки в некоторых местах сайта. Там, где это необходимо - уменьшены',
                    ],
                },
            ],
        },
        {
            version: '1.4.3',
            date: '03.03.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: ['Исправлен баг, когда при применении фильтров сбрасывалась сортировка'],
                },
                {
                    title: 'Улучшения',
                    description: ['Добавлены бренды: Saucony, Jordan, Carhartt WIP, Dickies'],
                },
            ],
        },
        {
            version: '1.5.0',
            date: '15.03.2024',
            versionBlock: [
                {
                    title: 'Нововведения',
                    description: [
                        'Разработана бонусная накопительная система для покупателей. Теперь сумма ваших покупок складывается в общую сумму выкупа и просчитывается ваша персональая скидка на весь ассортимент! Найти эту информацию можно в личном профиле, в виджете "Бонусная программа". Чем выше сумма выкупа - тем больше скидка',
                        'Разработана система поиска по клиентам. Теперь менедер может найти вас по адресу электронной почты при личном посещении магазина и предоставить персональную скидку',
                    ],
                },
            ],
        },
        {
            version: '1.6.0',
            date: '19.03.2024',
            versionBlock: [
                {
                    title: 'Нововведения',
                    description: [
                        'Разработана система промокодов. Теперь вы можете купить электронный сертификат или промокод на определенную сумму (Например, для подарка). Во время покупки менеджер вам сгенерирует QR-код, котрый будет вести на страницу промокода',
                        'Промокод можно будет применить в корзине. Финальная сумма к оплате будет равняться общей сумме с учетом различных возможных скидок минус номинал промокода',
                    ],
                },
            ],
        },
        {
            version: '1.6.1',
            date: '22.03.2024',
            versionBlock: [
                {
                    title: 'Исправление',
                    description: ['Исправлен баг, когда кнопка "Оплатить" не нажималась без применения промокода'],
                },
                {
                    title: 'Улучшения',
                    description: ['Добавлены бренды: Burberry, Tommy Hilfiger, Armani Exchange, Polo Ralph Lauren, Lacoste, LiNing'],
                },
            ],
        },
        {
            version: '1.6.2',
            date: '11.05.2024',
            versionBlock: [
                {
                    title: 'Исправление',
                    description: ['Упрощена валидация форм, теперь можно более нативно работать с личными данными в формах'],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Теперь обязательные для заполнения поля отмечаются знаком "*"',
                        'Добавлена ссылка для перехода в корзину сразу после добавления товара в корзину',
                        'Обновлен интерфейс выбора доставки. Теперь можно выбрать между самовывозом и доставкой через СДЭК',
                    ],
                },
            ],
        },
        {
            version: '1.7.0',
            date: '19.05.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Благодаря новому роутингу, описанному в разделе "Улучшения", исправлена проблема, когда фильтры и метод сортировки терялись после переключения пола/типа у товаров',
                        'Небольшие оптимизации страницы товаров благодаря удалению некоторых промежуточных действий и функций',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Переработана система роутинга на сайте, которая теперь включает в себя логику работы с адресной строкой браузера. Благодаря этому упрощена работа с сортировкой и фильтрами. Параметры и значения теперь запоминаются, и после обновления страницы товары появляются уже отфильтрованными и отсортированными',
                        'Добавлен новый фильтр - по типу: Одежда, Обувь, Аксессуары',
                        'Полностью переработано мобильное меню, которое теперь включает в себя множество категорий: Одежда, Обувь, Аксессуары, Новинки, Скидки, Бренды. Также доступны ссылки на корзину, избранное, профиль и страницу для заказа товаров',
                        'Добавлены анимации в меню для улучшения пользовательского опыта',
                        'На главную страницу добавлены блоки с категориями для удобной навигации по сайту',
                        'Обновлен блок с ссылками на соцсети в подвале сайта. Иконки увеличены, покрашены в свои цвета, а текстовые названия были удалены',
                    ],
                },
            ],
        },
        {
            version: '1.7.1',
            date: '21.05.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: ['Исправлен небольшой баг, когда в мобильном меню в safari могла пропасть кнопка Войти/Профиль'],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Уменьшен шрифт на основных информирующих страницах для большего комфорта при чтении',
                        'Удален блок "Горячие предложения" из-за ненадобности',
                        'Обновление страницы с версиями сайта - теперь каждая версия это выпадающий список с текстом изменений',
                    ],
                },
            ],
        },
        {
            version: '1.7.2',
            date: '22.05.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: ['Небольшие корректировки отступов на главной странице'],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'На страница "История версий" - блок с историей теперь отображается без яркой темно-серой обводки, а с просто с мягкой тенью',
                        'Добавлены бренды: Kappa, C.P. Comapany',
                        'Обновлены фото на главной странице',
                        'Добавлен адрес второго филиала',
                    ],
                },
            ],
        },
        {
            version: '1.8.0',
            date: '14.06.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Добавлено название 21 Store в контактах для второго филиала',
                        'Добавлено отображение ошибки при попытке добавить товар в избранное, если вы не авторизованы',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Увеличена ширина контента на сайте в 1.5 раза для лучшего восприятия',
                        'Добавлен полностью новый блок с фильтрами',
                        'Добавлена возможность выбирать несколько одинаковых фильтров, например 2 и более цветов. Ранее каждый фильтр был только один',
                        'Улучшена логика работы с фильтрами, теперь сайт запоминает то, что вы выбрали и при обновлении страницы воспроизводит это',
                    ],
                },
            ],
        },
        {
            version: '1.9.0',
            date: '17.06.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: ['Мелкие правки отступов', 'Замена тени на серый фон в активной фазе блока версии сайта'],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Новый дизайн для Заказов внутри профиля. Переход с таблицы на более удобную систему выпадающих деталей. Пользователь будет видеть краткую информацию о заказе и при желании может нажать на него, далее откроется уже полная информация',
                        'Добавлена возможность оплатить заказ в течение 10 минут после его оформления. Ранее при случайном или предамеренном выходе из оплаты - возможность оплатить отсутствовала',
                        'В каждый заказ добавлена кнопка "Проблема с заказом". Пользователь может написать сообщение менеджерам и они с ним свяжутся',
                    ],
                },
            ],
        },
        {
            version: '2.0.0',
            date: '30.08.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Обновлены мета данные, а именно: favicons, about, description, keywords',
                        'Небольшие исправления текста в Контактах, И Политике конфиденциальности',
                        'Небольшие внутренние оптимизации',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Разработан новый механизм загрузки контента. Теперь пользователь при открытии сайта будет видеть наш логотип как идентификатор загрузки, пока не загрузится вся важная информация - шрифты, картинки, список товаров и тд. Это предотвратит резкие скачки контента и неподготовленные к взаимодействию интерфейсы на время полной загрузки',
                        'Обновлена главная страница: контент увеличен по ширине, добавлена карусель из фотографий наших магазинов',
                        'Полностью переработана шапка сайта: убраны ненужные элементы, заменени иконки, добавлены категории для удобной навигации по сайту',
                        'Блок с категориями по полу и поиск по сайту переехали в обновленный Header',
                        'Прочие мелкие нововведения',
                    ],
                },
            ],
        },
        {
            version: '2.0.1',
            date: '02.09.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Внутренние оптимизации кода',
                        'Исправление неточных маршрутов в навигации по категориям',
                        'Исправление расположения модальных окон - возврат в центр, до этого прижимались ближе к верхней границе после переноса логики поиска в модальное окно',
                    ],
                },
                {
                    title: 'Улучшения',
                    description: ['Обновление описания магазина на главной странице'],
                },
            ],
        },
        {
            version: '2.0.2',
            date: '14.09.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: [
                        'Оптимизация работы шапки сайта',
                        'Исправление некоторых ошибок, которых не видно пользователю',
                        'Исправление высоты бегущей строки',
                    ],
                },
            ],
        },

        {
            version: '2.0.3',
            date: '26.09.2024',
            versionBlock: [
                {
                    title: 'Исправления',
                    description: ['Исправлен баг, при котором нельзя было выбрать сортировку', 'Исправлено название компании в нескольких местах'],
                },
                {
                    title: 'Улучшения',
                    description: [
                        'Улучшено отображение фото в карточке товара',
                        'Добавлена логика сброса значений сортировки и фильтров при переходе на другую категорию пола',
                    ],
                },
            ],
        },
    ],
};
