import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import ScrollToTop from './components/commons/ScrollToTop/ScrollToTop';
import { ModalImg } from './components/commons/ModalImg';
import { MainLoader } from './components/commons/MainLoader';
import { handleAppLoaded } from './utils';

window.addEventListener('load', handleAppLoaded);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <MainLoader />
            <ModalImg />
            <ScrollToTop />
            <App />
        </Provider>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
