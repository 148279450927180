import { useEffect } from 'react';
import { COLORS } from '../../constants';
import { HeaderLogoSVG } from '../../svg';
import { Animation } from './Animation';

export const MainLoader = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <div id='main-loader' className='main-loader'>
            <Animation pulse>
                <HeaderLogoSVG className='main-loader__img' color={COLORS.white.value} />
            </Animation>
            <span style={{ color: 'white' }}>Загрузка...</span>
        </div>
    );
};
