import React from 'react';

const Preloader = ({ size = '150', className }) => {
    return (
        <svg viewBox='20 20 60 60' enableBackground='new 0 0 0 0' xmlSpace='preserve' width={size} height={size} className={className}>
            <path fill='#9D9D9D' d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'>
                <animateTransform
                    attributeName='transform'
                    attributeType='XML'
                    type='rotate'
                    dur='1s'
                    from='0 50 50'
                    to='360 50 50'
                    repeatCount='indefinite'
                />
            </path>
        </svg>
    );
};

export default Preloader;
