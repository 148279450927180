import { CATEGORIES_MOBILE } from '../../constants';
import basketIcon from '../../img/basket.png';
import deliveryIcon from '../../img/delivery.png';
import favoriteIcon from '../../img/favorite.png';
import userIcon from '../../img/user.png';
import arrowIcon from '../../img/arrow.png';
import { Animation } from '../commons/Animation';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { useMobileMenu } from '../../hooks';
import { NavLink } from 'react-router-dom';
import CustomModal from '../commons/AuthPopup';

export const HeaderMobileMenu = () => {
    const isAuth = useSelector((state) => state.auth.isAuth);
    const [openIndex, setOpenIndex] = useState(null);
    const { isMobileMenuOpen, closeMobileMenu } = useMobileMenu();

    if (!isMobileMenuOpen) {
        return null;
    }

    return (
        <>
            <div className='mobile-menu__blur' onClick={closeMobileMenu}></div>
            <Animation x={30} className='mobile-menu__block'>
                <nav>
                    <ul className='mobile-menu__wrapper flexColumn gap-2 items-end'>
                        {CATEGORIES_MOBILE.map((category, index) => (
                            <React.Fragment key={index}>
                                <li className='mobile-menu__element'>
                                    <div className='mobile-menu__category' onClick={() => setOpenIndex(openIndex === index ? null : index)}>
                                        <img
                                            src={arrowIcon}
                                            alt=''
                                            className={`mobile-menu-arrow ${openIndex === index ? 'mobile-menu-arrow__rotate' : ''}`}
                                        />
                                        {category.title}
                                    </div>
                                    {openIndex === index && (
                                        <Animation y={-10} speed={0.5}>
                                            <div className='mobile-menu__content flexColumn gap-1 items-end'>
                                                {category.links.map((link, linkIndex) => (
                                                    <NavLink className='mobile-menu__link' to={link.to} onClick={closeMobileMenu} key={linkIndex}>
                                                        {link.title}
                                                    </NavLink>
                                                ))}
                                            </div>
                                        </Animation>
                                    )}
                                </li>
                                <div className='mobile-menu__line'></div>
                            </React.Fragment>
                        ))}

                        <li className='mobile-menu__element'>
                            <NavLink className='mobile-menu__link' to={'/order'} onClick={closeMobileMenu}>
                                Под заказ
                                <img src={deliveryIcon} alt='' style={{ width: '30px' }} />
                            </NavLink>
                        </li>
                        <li className='mobile-menu__element'>
                            <NavLink className='mobile-menu__link' to={'/basket'} onClick={closeMobileMenu}>
                                Корзина
                                <img src={basketIcon} alt='' style={{ width: '30px' }} />
                            </NavLink>
                        </li>
                        <li className='mobile-menu__element'>
                            <NavLink className='mobile-menu__link' to={'/favorites'} onClick={closeMobileMenu}>
                                Избранное
                                <img src={favoriteIcon} alt='' style={{ width: '30px' }} />
                            </NavLink>
                        </li>
                        {isAuth ? (
                            <li className='mobile-menu__element'>
                                <NavLink className='mobile-menu__link' to={'/profile'} onClick={closeMobileMenu}>
                                    Профиль
                                    <img src={userIcon} alt='' style={{ width: '30px' }} />
                                </NavLink>
                            </li>
                        ) : (
                            <li className='mobile-menu__element' onClick={closeMobileMenu}>
                                <CustomModal text='Войти' />
                            </li>
                        )}
                    </ul>
                </nav>
            </Animation>
        </>
    );
};
