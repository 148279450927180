import { COMPANY_INFO } from '../_company';
import { EMOJI } from '../emoji';

export const ORDER = {
    title: `Под заказ ${EMOJI.mail[0]}`,
    items: [
        {
            subtitle:
                'Мы понимаем, что каждый клиент уникален, как и его потребности, поэтому, если вы ищете что-то особенное, мы можем привезти это под заказ. У нас вы можете заказать любую вещь (одежду, обувь, аксессуар), которой нет в текущем ассортименте магазина.',
        },
        {
            subtitle: 'Сроки доставки с момента оплаты составляют 14-25 дней (также есть экспресс-доставка 7-10 дней за дополнительную плату).',
        },
        {
            subtitle:
                'Услуга “под заказ” — отличная возможность для тех, кто ищет эксклюзивные товары, или просто хочет заказать понравившуюся вещь нужного размера. Мы ценим каждого клиента и идём навстречу для достижения общей цели - напишите ваш запрос, и наши менеджеры сориентируют вас по стоимости, предложат альтернативные варианты, если это необходимо.',
        },
        {
            subtitle:
                'С 2011 года мы продаем только оригинальную продукцию. В течение этого времени мы стараемся улучшать наши услуги и расширять ассортимент, чтобы вы были довольны! Спасибо, что выбираете нас!',
        },
        {
            subtitle: [
                'Чтобы уточнить интересующую вас модель, вы можете написать напрямую нам в телеграм ',
                { text: '@store21sportnn', link: COMPANY_INFO.contacts.telegram.link, internal: false },
                ' или заполнить форму:',
            ],
        },
    ],
};
