import { createSlice } from '@reduxjs/toolkit';
import { filtersInitialState } from './initialStates/filtersInitialState';

const filtersSlice = createSlice({
    name: 'filters',
    initialState: filtersInitialState,
    reducers: {
        setFilters: (state, action) => {
            Object.assign(state, action.payload);
        },
        setMinPrice: (state, action) => {
            state.minPrice = action.payload;
        },
        setMaxPrice: (state, action) => {
            state.maxPrice = action.payload;
        },
        setSex: (state, action) => {
            state.sex = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setFiltersApplied: (state, action) => {
            state.filtersApplied = action.payload;
        },
        setPermissionToRequest: (state, action) => {
            state.permissionToRequest = action.payload;
        },
        resetFilters: (state) => {
            const currentSex = state.sex;
            Object.assign(state, filtersInitialState);
            state.sex = currentSex;
        },
    },
});

export const { setFilters, resetFilters, setMinPrice, setMaxPrice, setSex, setType, setPermissionToRequest, setFiltersApplied } =
    filtersSlice.actions;

export default filtersSlice.reducer;
