import { useState } from 'react';
import { CATEGORIES, COLORS } from '../../constants';
import { NavLink } from 'react-router-dom';
import { Animation } from '../commons/Animation';

export const HeaderCategoriesDesktop = () => {
    const [openIndex, setOpenIndex] = useState(null);

    return (
        <div className='flex header__middle-position'>
            {CATEGORIES.map((category, index) => (
                <li
                    key={index}
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    onMouseEnter={() => setOpenIndex(index)}
                    onMouseLeave={() => setOpenIndex(null)}
                >
                    <NavLink
                        className='header__link'
                        to={category.to}
                        style={{
                            color: index === 0 ? COLORS.green.value : index === 5 ? COLORS.red.value : 'none',
                        }}
                    >
                        {category.title}
                    </NavLink>

                    {openIndex === index && (
                        <Animation y={-10} speed={0.5} className='desktop-menu'>
                            <div className='flex-container'>
                                {[0, 1, 2].map((column) => (
                                    <div className='column flexColumn gap-1' style={{ textAlign: 'left', alignItems: 'flex-start' }} key={column}>
                                        {category.links
                                            .filter((_, linkIndex) => linkIndex % 3 === column)
                                            .map((link, linkIndex) => (
                                                <div
                                                    className='flexColumn gap-2'
                                                    style={{ textAlign: 'left', alignItems: 'flex-start' }}
                                                    key={linkIndex}
                                                >
                                                    <NavLink
                                                        className='flex header__link-title'
                                                        to={link.to}
                                                        style={{
                                                            fontWeight: link.isHeader ? 500 : 400,
                                                        }}
                                                    >
                                                        {link.title}
                                                    </NavLink>
                                                    {link.links && (
                                                        <div
                                                            className='flexColumn'
                                                            style={{ textAlign: 'left', alignItems: 'flex-start', gap: '0.5rem' }}
                                                        >
                                                            {link.links.map((subLink, subLinkIndex) => (
                                                                <NavLink className='flex header__link-subtitle' to={subLink.to} key={subLinkIndex}>
                                                                    {subLink.title}
                                                                </NavLink>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                ))}
                            </div>
                        </Animation>
                    )}
                </li>
            ))}
        </div>
    );
};
