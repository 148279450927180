import { NavLink } from 'react-router-dom';
import SearchPopup from '../Search/SearchPopup';
import { BasketIconSVG, FavoriteIconSVG, UserIconSVG } from '../../svg';
import CustomModal from '../commons/AuthPopup';
import { COLORS } from '../../constants';
import { Badge } from '../commons/Badge';
import { useSelector } from 'react-redux';

export const HeaderActions = () => {
    const isAuth = useSelector((state) => state.auth.isAuth);
    const basketItems = useSelector((state) => state.items.basketItems) || { items: [] };
    const favoriteItems = useSelector((state) => state.items.favoritesItems) || [];

    const isBasketEmpty = !basketItems.items || basketItems.items.length === 0;
    const isFavoritesEmpty = !favoriteItems || favoriteItems.length === 0;

    return (
        <div className='header__right-position'>
            <nav className='header__nav'>
                <span className='header__nav-link header__right-search'>
                    <SearchPopup />
                </span>
                <NavLink className='header__nav-link' to={'/favorites'} style={{ position: 'relative' }}>
                    <FavoriteIconSVG />
                    {isAuth && isFavoritesEmpty ? null : <Badge value={favoriteItems?.length || 0} color={COLORS.blue.value} />}
                </NavLink>
                <NavLink className='header__nav-link' to={'/basket'} alt='Корзина' style={{ position: 'relative' }}>
                    <BasketIconSVG />
                    {isAuth && isBasketEmpty ? null : <Badge value={basketItems?.items?.length || 0} color={COLORS.blue.value} />}
                </NavLink>
                {isAuth ? (
                    <NavLink className='header__nav-link' to={'/profile'}>
                        <UserIconSVG />
                    </NavLink>
                ) : (
                    <span className='header__nav-link'>
                        <CustomModal />
                    </span>
                )}
            </nav>
        </div>
    );
};
