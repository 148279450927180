import { COMPANY_INFO, PERSONS_INFO } from '../../constants';

export const HeaderContacts = () => (
    <div className='header__logo-about flexColumn'>
        <h1 className='header__logo-title'>{COMPANY_INFO.nameEng}</h1>
        <a className='header__logo-phoneNumber header__nav-link' href={`tel:${PERSONS_INFO.we.phoneNumber}`}>
            {PERSONS_INFO.we.phoneNumber}
        </a>
        <h4 className='header__logo-openingHours'>{COMPANY_INFO.openingHours}</h4>
    </div>
);
