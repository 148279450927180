export const authInitialState = {
    isAuth: false,
    isFetching: false,
    id: null,
    createdAt: null,
    updatedAt: null,
    email: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    city: null,
    pointOfIssue: null,
    amountOfPurchases: null,
    personalSale: null,
    role: null
};