import { EMOJI } from '../emoji';

export const TRACKING = {
    title: `Где мой заказ? ${EMOJI.face.thinking[0]}`,
    items: [
        {
            title: 'Если вы сделали заказ на нашем сайте',
            subtitle:
                'Его можно отследить в личном профиле. Для этого нажмите на иконку профиля в шапке сайта, далее пролистайте вниз. Там будут отображаться все ваши заказы. Они отсортированы в порядке их оформления.',
        },
        {
            subtitle: 'Последняя колонка таблицы и является статусом вашего заказа.',
        },
        {
            title: 'Узнать статус вашего заказа также можно напрямую у менеджера магазина',
            subtitle:
                'Если вы сделали заказ через Avito или Instagram, менеджер должен был вам прислать трек-номер для отслеживания посылки. Если возникли какие-либо вопросы или проблемы, вы можете сообщить об этом нам.',
        },
        {
            title: 'Сделать это можно написав в телеграм @sportnn21',
        },
    ],
    lastText: 'Удачных покупок!',
};
