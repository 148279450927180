import multicolor from '../../img/multicolor.png';

export const COLORS = {
    red: { value: '#E53E3E', name: 'Красный' },
    blue: { value: '#3182CE', name: 'Синий' },
    yellow: { value: '#FDE910', name: 'Желтый' },
    multicolor: { value: `url(${multicolor})`, name: 'Разноцветный' },
    black: { value: '#04060C', name: 'Черный' },
    white: { value: '#FFFFFF', name: 'Белый' },
    gray: { value: '#A0AEC0', name: 'Серый' },
    green: { value: '#16a34a', name: 'Зеленый' },
    purple: { value: '#9333ea', name: 'Фиолетовый' },
    pink: { value: '#f472b6', name: 'Розовый' },
    brown: { value: '#6B4023', name: 'Коричневый' },
    beige: { value: '#E2D8C2', name: 'Бежевый' },
    orange: { value: '#ED8936', name: 'Оранжевый' },
};
