import { useDispatch, useSelector } from 'react-redux';
import { closeModalImg } from '../../redux/AppReducer';
import { useEffect } from 'react';

export const ModalImg = () => {
    const img = useSelector((state) => state.app.modalImg);
    const dispatch = useDispatch();

    useEffect(() => {
        if (img) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [img]);

    const close = () => {
        dispatch(closeModalImg());
    };

    if (!img) {
        return null;
    }
    return (
        <div className='scalingImage'>
            <img src={img} alt='' className='scalingImage__img' onClick={close} />
        </div>
    );
};
