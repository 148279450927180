export const ARTICLES = {
    title: `Легендарные модели Adidas`,
    items: [
        {
            title: 'ADIDAS SAMBA - ВЫ ЗНАЕТЕ ЭТУ ОБУВЬ',
            subtitle:
                'Вы знаете adidas Samba. Знаковый дизайн с тремя полосками мгновенно узнаваем. Кроссовки adidas Samba известны своими спортивными характеристиками, но их популярность в качестве обуви для активного отдыха не менее впечатляюща. Когда обувь выглядит настолько хорошо, уличные модники просто обязаны были сделать ее своей. Это неудивительно, если принять во внимание крутую эстетику черных adidas Samba, полностью кожаных с белыми контрастными полосками, которые так же легко смотрятся, как и на ваших ногах. Для более яркого образа adidas Samba в белом цвете обладают универсальностью теннисных кроссовок для повседневной носки. В любом цветовом решении плавные линии и классическая резиновая подошва adidas Samba Classic - это то, что нужно для улицы.',
        },
        {
            title: 'ЛЕГЕНДА ФУТБОЛА С ТРЕМЯ ПОЛОСКАМИ',
            subtitle:
                'Впервые выпущенные в 1949 году оригинальные кроссовки Samba стали одними из первых кроссовок adidas с культовым дизайном "3 полоски". Изящный дизайн и невероятное сцепление с поверхностью сделали adidas Originals Samba самыми известными кроссовками для мини-футбола своего времени. Легендарный стиль и особенности этих кроссовок adidas были усовершенствованы и модернизированы, чтобы создать еще более впечатляющие кроссовки adidas. Толстый слой замши покрывает носочную часть, а удлиненный плоский язычок (для натяжения шнурков) обеспечивает отличную защиту от мяча, не говоря уже о более точном ударе. Вам будет очень комфортно на поле, вы оцените носочную форму и дополнительные отверстия для шнурков, чтобы ваши кроссовки adidas Samba двигались как единое целое с вашими ногами. Известные в футболе благодаря своему улучшенному сцеплению с поверхностью (что позволяет выполнять все неожиданные действия на поле), они теперь доступны в женских и мужских моделях adidas Samba. Знаменитая спортивная обувь даст футболистам преимущество над соперниками.',
        },
        {
            title: 'ОБУВЬ ADIDAS ORIGINALS GAZELLE',
            subtitle:
                'Нет ничего, с чем бы не сочетались кроссовки adidas Originals Gazelle. Универсальный, низкопрофильный и несерьезный внешний вид - это то, что сделало культовые кроссовки таким культурным наследием. За годы существования adidas Gazelle было много разных итераций, и наша подборка adidas Original Gazelle дает вам возможность почувствовать историю этих кроссовок благодаря разнообразию цветов и материалов. Вы не ограничены в стиле с кроссовками adidas Originals Gazelle, и вы также не ограничены в месте. Только посмотрите, где только не появлялись кроссовки adidas Originals Gazelle - на сценах, подиумах, террасах... а теперь и там, где вы их носите, а это будет везде. Дело в том, что обувь adidas Originals Gazelle - это не про ограничения. Они о том, как впустить в себя каждую часть дня и выразить каждую часть себя так, как тебе хочется.',
        },
        {
            subtitle:
                'Откройте для себя смелые и привлекающие внимание расцветки в ярких, насыщенных оттенках или придерживайтесь минимализма и выбирайте кроссовки adidas Originals Gazelle в светлых, приглушенных тонах. Вы найдете премиальные модели с верхом из гладкой кожи или оригинальной мягкой замши, а иногда и с сочетанием обоих материалов. На всех наших кроссовках adidas Originals Gazelle красуются культовые 3 полоски. Вы сразу поймете, что это Gazelle, ведь один из их фирменных признаков - переход к зубчатым полоскам. В свое время кроссовки OG Gazelle предназначались для тренировок и гандбола, и хотя сейчас они больше не предназначены для спорта, adidas Originals Gazelle по-прежнему понимают важность комфорта и обеспечивают его во всех ваших повседневных движениях. Они появились в 1966 году, были переосмыслены в 1991-м и снова обновлены в 2016-м - все эти годы мы продолжаем пересматривать и чествовать разными релизами. Ознакомьтесь с разными стилями наших кроссовок adidas Originals Gazelle и зашнуруйте ту модель, которая подходит именно вам.',
        },
        {
            title: 'ADIDAS ORIGINALS SUPERSTAR',
            subtitle:
                'На переполненных баскетбольных аренах, на концертах и улицах по всему миру кроссовки adidas Originals Superstar становятся все лучше и лучше. Их кожаный верх и фирменные носки-ракушки сегодня известны во всем мире, но в 1969 году, когда кроссовки adidas Originals Superstar были разработаны для баскетбольных площадок, о таких характеристиках даже не слышали. Благодаря своей защитной конструкции они вскоре стали популярны среди звезд НБА. А после того, как хип-хоп группа Run-DMC нашла свою платформу в музыке, у них вскоре появились преданные поклонники кроссовок. Хотя на звание самых востребованных кроссовок в мире было немало претендентов, adidas Originals Superstars, несомненно, входят в число великих, сочетая в себе богатую предысторию и неповторимый внешний вид. А если учесть, что маленькие кроссовки обладают не меньшим стилем и ретро-шармом, чем их взрослые собратья, то пополнить ими свою коллекцию никогда не поздно и не рано.',
        },
        {
            subtitle:
                'Постоянно развивающийся модельный ряд adidas Originals Superstar включает в себя строгие модели и знаменитые коллаборации, смелые принты, металлические цвета и 3 полоски всех оттенков. Знаковые детали остались практически неизменными, а комфорт находится на современном уровне: мягкий верх с подкладкой и гибкая подошва, которую можно носить постоянно. Какие бы кроссовки adidas Originals Superstar ты ни выбрал, их классические линии будут сочетаться со всем - от тренировочных шорт до твоих любимых джинсов, собирая восхищенные взгляды и лайки в течение всего дня.',
        },
    ],
};
