import { configureStore } from '@reduxjs/toolkit';
import appReducer from './AppReducer';
import itemsReducer from './ItemsReducer';
import authReducer from './authReducer';
import OrderReducer from './OrderReducer';
import filtersSlice from './filtersSlice';
import sortingSlice from './sortingSlice';
import clientSlice from './ClientReducer';
import promocodeSlice from './PromoCodeReducer';

export default configureStore({
    reducer: {
        app: appReducer,
        items: itemsReducer,
        auth: authReducer,
        order: OrderReducer,
        filters: filtersSlice,
        sorting: sortingSlice,
        client: clientSlice,
        promocode: promocodeSlice,
    },
});
