export const filtersInitialState = {
    color: [],
    brand: [],
    size: [],
    sex: null,
    type: [],
    permissionToRequest: false,
    minPrice: null,
    maxPrice: null,
};
