import React from 'react';
import Popup from 'reactjs-popup';

import { SearchIconSVG } from '../../svg';
import Search from './Search';

const SearchPopup = () => {
    return (
        <Popup
            trigger={
                <span>
                    <SearchIconSVG />
                </span>
            }
            modal
            nested
        >
            {(close) => (
                <div style={{ width: '600px' }} className='popup-search'>
                    <Search onClose={close} />
                </div>
            )}
        </Popup>
    );
};

export default SearchPopup;
