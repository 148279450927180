import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';

export const FormField = ({ name, type, label, touched, errors, required = false, isTextarea = false, isWide = false, ...props }) => {
    const id = `${name}_${Math.random().toString(36).substr(2, 5)}`;

    return (
        <div className={classNames('formBlock wh100', { 'admin__form-block': isWide })}>
            <Field
                as={isTextarea && 'textarea'}
                rows={5}
                type={type}
                name={name}
                id={id}
                required={required}
                placeholder=' '
                className={classNames('input wh100', { 'input-error': touched[name] && errors[name] })}
                {...props}
            />
            <label className={classNames('input__label', { 'input__label-error': touched[name] && errors[name] })} htmlFor={id}>
                {label} {required && '*'}
            </label>
            <ErrorMessage name={name} component='span' className='input__error' />
        </div>
    );
};
