import { useDispatch, useSelector } from 'react-redux';
import { closeMobileMenu, openMobileMenu } from '../redux/AppReducer';
import { useEffect } from 'react';

export const useMobileMenu = () => {
    const dispatch = useDispatch();
    const isMobileMenuOpen = useSelector((state) => state.app.isMobileMenuOpen);

    const handleOpenMobileMenu = () => {
        dispatch(openMobileMenu());
    };

    const handleCloseMobileMenu = () => {
        dispatch(closeMobileMenu());
    };

    useEffect(() => {
        document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'visible';
    }, [isMobileMenuOpen]);

    return {
        isMobileMenuOpen,
        openMobileMenu: handleOpenMobileMenu,
        closeMobileMenu: handleCloseMobileMenu,
    };
};
